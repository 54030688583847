<script setup>
import { ref, watch, onMounted, onUnmounted } from "vue";
import { useRoute } from "vue-router";

// FIXME: why are we using element-plus for loading?
// import { ElLoading } from "element-plus";

import { storeToRefs } from "pinia";
import { useModalStore } from "@/stores/modals";
import CryptoJS from 'crypto-js';

import LayoutDefault from '@/layouts/LayoutDefault.vue';
import PlanDesktop from "@/components/plans/PlanDesktop.vue";
import CallModal from "@/components/basic/CallModal.vue";
import PlanModal from "@/components/basic/PlanModal.vue";
import FilterModal from "@/components/basic/FilterModal.vue";
import EligibilityModal from "@/components/basic/EligibilityModal.vue";

// import ServicePopup from "@/components/basic/ServicePopup.vue";
import UsagePopup from "@/components/basic/UsagePopup.vue";
const base = import.meta.env.BASE_URL;
const websiteUrl = import.meta.env.VITE_COMPARABLE_SITE_URL;

const data = useModalStore();
// const { servicePopup, setRetailerInfo } = useModalStore();
const { setRetailerInfo } = useModalStore();
const {
  showCallModal,
  showPlanModal,
  showFilterModal,
  isLoading,
  plans,
  dist,
  tariff,
  elecDistStr,
  gasDistStr,
  hasElec,
  hasGas,
  showElec,
  showGas,
  alintaAccessToken,
} = storeToRefs(data);

watch(showElec, () => data.showFuel(showElec, showGas));
watch(showGas, () => data.showFuel(showElec, showGas));

const handleCloseModal = (e) => {
    if (e.keyCode === 27) {
        showCallModal.value = false;
        showPlanModal.value = false;
        showFilterModal.value = false;
    }
};

const route = useRoute();

onMounted(() => {
    isLoading.value = true;
    data.resetOffers();

    data.setAlintaAccessToken().then(() => {
        let _nmi = CryptoJS.AES.decrypt(route.params.nmi, "N1788D6vrv9stpDcZjtN8").toString(CryptoJS.enc.Utf8);
        let _checksum = route.params.checksum;
        let _postcode = route.params.postcode;
        let _address = route.params.address;
        let _suburb = route.params.suburb;
        
        data.getAddress(_nmi, _checksum, _postcode, _address, _suburb).then((response) => {
            
          if (response.utilityAddress) {

          let _AddrSearchParams = {
              'addressType': 'streetAddress',
              'siteIdentifier': null,
              'unitNumber': (response.utilityAddress.flatOrUnitNumber) ? response.utilityAddress.flatOrUnitNumber : null,
              'unitType': null,
              'floorNumber': (response.utilityAddress.floorOrLevelNumber) ? response.utilityAddress.floorOrLevelNumber : null,
              'floorType': null,
              'streetNumber': response.utilityAddress.houseNumber,
              'streetNumberSuffix': response.utilityAddress.houseNumberSuffix,
              'streetNumberTo': null,
              'streetNumberToSuffix': null,
              'streetName': response.utilityAddress.streetName,
              'streetSuffix': null,
              'streetType': response.utilityAddress.streetType,
              'suburb': response.utilityAddress.suburbOrPlaceOrLocality,
              'state': response.utilityAddress.stateOrTerritory,
              'postcode': response.utilityAddress.postcode,
            }

            data.getMIRN(_AddrSearchParams, alintaAccessToken.value).then((response) => {
              if (response.mirnMatches) {
                data.setMirnInfo(response.mirnMatches[0]);
              }
            });
          }

          // servicePopup(data.dist);
          isLoading.value = false;
          data.setAddressState(route.params.state);
          
          if (data.formatted_address == null) {
              data.formatted_address = route.params.address
          }
        });
    });

    

    document.addEventListener("keyup", handleCloseModal);

    if(!hasElec && !hasGas) {
        let url = `${websiteUrl}contact-us/?address=no-plans`;
        window.location.href = url;
    }
});

onUnmounted(() => {
    document.removeEventListener("keyup", handleCloseModal);
});

const isDealsLoaded = ref(true);

function viewDeals() {
    isDealsLoaded.value = false;
}
</script>

<template>

  <div v-if="isLoading" class="w-screen h-screen flex flex-col justify-center">
     <img class="mx-auto h-20" :src="base + 'icons/' + 'Spinner-1s-200px.gif'"/>
  </div>

  <div v-else>
    <layout-default>
      <div v-if="!hasElec && !hasGas" class="mt-8">
        <p class="text-accent font-poppins mb-8">
          This address doesn't show any associated plans on our record, please reach out to our administrator.
        </p>
      </div>

      <div v-else class="w-full lg:px-24">

        <div class="w-full mx-auto" v-if="isDealsLoaded">
          <div class="h-full p-4 flex flex-col">
            <div class="border border-b rounded-xl px-8 pb-8 relative">
              <img class="mx-auto h-16 -mt-xl mb-lg" :src="base + 'icons/' + 'COMPARABLE-Logo-Transparents-04.png'"/>
              <div class="top flex-grow">
                  <h2 class="font-quicksand text-2xl text-center font-bold mb-md">Search Settings</h2>
                  <p class="font-poppins text-base pb-4">
                    Based on the address selected by you, for electricity, we have
                    identified your meter to be on a {{ tariff }} in the
                    {{ elecDistStr ?? dist?.distributor }} distribution zone.
                  </p>
                  <p class="font-poppins text-base" v-if="hasGas">
                    For gas, we have identified your distributor to be
                    {{ gasDistStr ?? dist?.gasInfo.zone }}.
                  </p>
              </div>
              <div class="2 flex-grow">
                  <p class="font-poppins text-xs hidden">
                    We found {{ plans.length }} Plans for you.
                  </p>
                  <br />
                  <div class="flex flex-col lg:flex-row gap-4 justify-between" v-if="hasElec && hasGas">
                    <label
                      class="relative inline-flex items-center mr-2 cursor-pointer"
                    >
                      <input
                        type="checkbox"
                        value=""
                        class="sr-only peer"
                        v-model="showElec"
                      />
                      <div
                        class="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-orange-300 dark:peer-focus:ring-orange-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue"
                      ></div>
                <div>
                      <span class="font-poppins ml-3 text-sm font-medium text-black">Show Electricity</span>
                </div>
                    </label>

                  

                    <label class="relative inline-flex items-center cursor-pointer">
                      <input type="checkbox" value="" class="sr-only peer" v-model="showGas" />
                      <div class="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-teal-300 dark:peer-focus:ring-teal-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue"></div>
                      <span class="font-poppins ml-3 text-sm font-medium text-black block">Show Gas Plans</span>
                    </label>
                  </div>

                <div class="text-center">
                  <button
                    class="choose-plan-button w-full lg:w-96 uppercase bg-accent text-white font-quicksand font-bold shadow-lg rounded-full py-2 px-6 text-lg my-6"
                    @click="viewDeals"
                  >
                    View Offers &nbsp;&nbsp;&nbsp;<font-awesome-icon
                      icon="fa-solid fa-arrow-right"
                    />
                  </button>
                </div>
              </div>
            </div>
        
            <div class="overflow-y-scroll h-32 p-4 mt-4 bg-gray-0 rounded-lg border-2 border-gray-0 flex flex-row gap-4">
              <div class="flex-none">
                <img src="/icons/ri_information-fill.png" class="w-8" />
              </div>
              <div>
                <p class="text-sm font-poppins text-justify lg:text-left">
                  <b>Please note:</b> We may not have access to all of the products
                  available in your area. We do not compare all Energy retailers in
                  the market, or all Energy offers offered by all retailers. At times certain retailers or offers may not be available or offered to you. From time to time we may have better offers that are only available via our call centre.
                </p>
              
                <p class="text-sm pt-4 font-poppins text-justify lg:text-left">
                  The calculated costs are estimates only. The amount you will
                  actually pay will depend on your actual usage of gas or electricity
                  and the plan you are on. Comparable uses electricity and gas
                  consumption benchmarks from the Australian Energy Regulator to
                  simulate customer usage across multiple energy plans available to a customer's address.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div v-else>
          <div class=" ">
            <PlanDesktop v-if="!isLoading" :plans="plans" :state="route.params.state" :address="route.params.address" />
          </div>
          <!--
          <div class="hidden">
            <PlanMobile v-if="!isLoading" :plans="plans" />
          </div>
          -->
          <!-- <ServicePopup /> -->
          <UsagePopup />
          <CallModal />
          <PlanModal />
          <FilterModal />
          <EligibilityModal />
        </div>
      </div>
    </layout-default>
  </div>
</template>

<style scoped lang="scss"></style>
