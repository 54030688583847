<script setup>
import { computed, onMounted, ref } from "vue";
import { storeToRefs } from "pinia";
import { useModalStore } from "@/stores/modals";

// import SingleRateTariff from "./plan/SingleRateTariff.vue";
// import TimeOfUseTariff from "./plan/TimeOfUseTariff.vue";
import DemandCharges from "./plan/DemandCharges.vue";
import SolarFeedInTariff from "./plan/SolarFeedInTariff.vue";
import ControlledLoad from "./plan/ControlledLoad.vue";
import { camelCase } from "lodash";

const modalStore = useModalStore();
const { planTier, showPlanModal, plan, tariff, estimate } = storeToRefs(modalStore);
const base = import.meta.env.BASE_URL;
const activeName = ref("1");

// FIXME: This should be passed as a prop as to allow for dual fule plans
const contract = computed(() => {
  // return modalStore.plan.fuelType == "gas"
  //   ? modalStore.plan.gasContract
  //   : modalStore.plan.electricityContract;
});

const privacyPolicy = computed(() => {
  // return modalStore.retailer[modalStore.plan.brand].privacyPolicy;
});

const marketContract = computed(() => {
  // return modalStore.retailer[modalStore.plan.brand].contract;
});

const selAddress = computed(() => {
  return modalStore.address;
  //return "*** FIXME (PlanModal.vue <setAddress>) ***";
}); // FIXME: getQAddress("latest") ???

const defaultOfferUsage = computed(() => {
  // if (modalStore.plan.fuelType == "gas") return "125 mJ";
  // var isCl =
  //   modalStore.plan.fuelType == "gas"
  //     ? false
  //     : modalStore.dist.tariffFeatures.contLoad1 ||
  //       modalStore.dist.tariffFeatures.contLoad2;
  // var u = Math.round(
  //   modalStore.plan.distributor[modalStore.plan.fuelType].defaultOffer[
  //     isCl ? "withContLoad" : "withoutContLoad"
  //   ],
  //   0
  // );
  // return u + (modalStore.plan.fuelType == "gas" ? " mJ" : " kWh");
});

const feeTypeMap = {
  EXIT: "EXIT:",
  ESTABLISHMENT: "ESTABLISHMENT",
  LATE_PAYMENT: "LATE PAYMENT",
  DISCONNECTION: "DISCONNECTION",
  DISCONNECT_MOVE_OUT: "DISCONNECT (MOVE OUT)",
  DISCONNECT_NON_PAY: "DISCONNECT (NON PAY)",
  RECONNECTION: "RECONNECTION",
  CONNECTION: "CONNECTION",
  PAYMENT_PROCESSING: "PAYMENT PROCESSING",
  CC_PROCESSING: "CREDIT CARD PROCESSING",
  CHEQUE_DISHONOUR: "CHEQUE DISHONOUR",
  DD_DISHONOUR: "DIRECT DEBIT DISHONOUR",
  MEMBERSHIP: "MEMBERSHIP",
  CONTRIBUTION: "CONTRIBUTION",
  PAPER_BILL: "PAPER BILL",
  OTHER: "OTHER FEES",
};

const getPlanEligibilityList = (eligilibility) => {

  return eligilibility.split(/\r?\n/);
}; 
</script>
<template>
  <div v-if="plan && showPlanModal" class="fixed h-screen w-full bg-black bg-opacity-40 z-20 top-0 left-0">
    <div class="h-auto max-h-680px lg:w-5/6 xl:w-2/5 mx-4 lg:mx-auto overflow-auto z-30  px-3 pt-4 pb-8 rounded-xl border border-gray-2 text-left space-y-4 bg-white mt-10">
      <div class="top-control pb-1 text-center relative">
        <img class="h-16 mx-auto" :src="plan?.retailer?.logo" />
	      <a class="absolute top-0 right-0 cursor-pointer uppercase font-bold py-1.5 pl-4 text-sm" @click="showPlanModal = false" >
          <img class="h-6 inline-block" :src="base + 'icons/' + 'circle-xmark-solid.png'" />
        </a>
      </div>
      <div class="grid grid-cols-1 px-8 lg:px-12 py-2 text-left">
        <h1 class="font-poppins text-xl text-center mb-8">Detailed information about the company's services</h1>
        <div class="text-sm text-left">
		      <h2 class="font-poppins font-bold text-lg pb-2">{{ plan?.product?.product_name }}</h2>
          <p v-if="plan.retailer_cost > 0" class="font-poppins">
            The monthly estimate for {{ selAddress }} based on the average usage
            of {{ plan.usage }} for a {{ plan?.cust_type }} customer in
            the {{ plan?.distributor_name }} distribution
            area is ${{ Number(plan.estimated_monthly_price).toFixed(2) }}.
          </p>
        </div>
      </div>
      <div class="plan-features px-8 lg:px-12">
        <el-collapse v-model="activeName" accordion>
          <!-- Special Offer Tab -->
          <el-collapse-item v-if="plan.product?.special_offer" title="Special Offer" name="special_offer">
            <p class="font-poppins font-bold text-lg text-accent">{{ plan.product?.special_offer }}*<sup>1</sup></p>
            <p v-if="plan.product?.special_offer_desc" class="font-poppins">{{ plan.product?.special_offer_desc }}</p>
          </el-collapse-item>
          <!-- Features tab -->
          <el-collapse-item title="Features" name="1">
            <div class="">
              <ul class="font-poppins text-left">
                <li class="mb-2" v-if="plan.retailer?.feature_0">
                  <font-awesome-icon icon="fa-regular fa-circle-check" class="text-purple" />
                  <span class="text-sm pl-4">{{ plan.retailer?.feature_0 }}</span>
                </li>
                <li class="mb-2" v-if="plan.retailer?.feature_1">
                  <font-awesome-icon icon="fa-regular fa-circle-check" class="text-purple" />
                  <span class="text-sm pl-4">{{ plan.retailer?.feature_1 }}</span>
                </li>
                <li class="mb-2" v-if="plan.retailer?.feature_2">
                  <font-awesome-icon icon="fa-regular fa-circle-check" class="text-purple" />
                  <span class="text-sm pl-4">{{ plan.retailer?.feature_2 }}</span>
                </li>
                <li class="mb-2" v-if="plan.retailer?.feature_3">
                  <font-awesome-icon icon="fa-regular fa-circle-check" class="text-purple" />
                  <span class="text-sm pl-4">{{ plan.retailer?.feature_3 }}</span>
                </li>
                <li class="mb-2" v-if="plan.retailer?.feature_4">
                  <font-awesome-icon icon="fa-regular fa-circle-check" class="text-purple" />
                  <span class="text-sm pl-4">{{ plan.retailer?.feature_4 }}</span>
                </li>
              </ul>
            </div>
          </el-collapse-item>
          <!-- Plan Details Tab -->
          <el-collapse-item title="Plan Details" name="2">
            <div class=" text-left">
              <table class="font-poppins table-auto border-collapse border border-slate-400 plan_details_table" width="100%">
                <tbody>
                  <tr>
                    <td>
                      <h4 class="font-bold">Offer Type:</h4>
                      <p class="capitalize">{{ plan.product?.offer_type }}</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h4 class="font-bold">Offer ID:</h4>
                      <p>{{ plan.offer_id }}</p>
                    </td>
                  </tr>
                  <tr v-if="plan.plan_type != 'gas'">
                    <td>
                      <h4 class="font-bold">Tariff Type:</h4>
                      <p class="capitalize">{{ plan.tariff_type }}</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h4 class="font-bold">Cooling off days:</h4>
                      <p>10</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h4 class="font-bold">Exit Fees:</h4>
					            <p>0</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h4 class="font-bold">Eligibility:</h4>
                      <ul class="">
                        <li v-for="item in getPlanEligibilityList(plan?.product?.eligibility)">
                          <font-awesome-icon icon="fa-regular fa-circle-check" class="text-purple" />
                          <span class="text-sm pl-4 capitalize">{{ item.replace('*', '')  }}</span>
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h4 class="font-bold">Customer Type:</h4>
                      <p class="capitalize">{{ plan.cust_type }}</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </el-collapse-item>
          <!-- Rates Tab -->
          <el-collapse-item title="Rates" name="3">
            <div class="text-left">
              <div v-html="plan.rates_html" class="font-poppins text-sm"></div>
            </div>
          </el-collapse-item>
          <!-- Discounts Tab -->
          <el-collapse-item title="Discounts" name="discounts">

            <!-- elec discounts -->
            <table v-if="plan.plan_type == 'elec'" class="font-poppins table-auto border-collapse border border-slate-400 plan_details_table" width="100%">
              <tbody>
                <!-- Pay on Time -->
                <tr>
                  <td>
                    <p class="font-bold">Pay on time</p>
                    <p>{{ plan.product?.elec_payontime_desc }}</p>
                    <div v-if="plan.product?.elec_payontime_disc == 1">
                      <p class="text-accent font-bold">{{ plan.product?.elec_payontime_amt }}</p>
                      <p>{{ plan.product?.elec_payontime_disctype }}</p>
                    </div>
                    <div v-else class="text-accent font-bold">Discount Not Available</div>
                  </td>
                </tr>
                <!-- Guaranteed -->
                <tr>
                  <td>
                    <p class="font-bold text-left">Guaranteed</p>
                    <p>{{ plan.product?.elec_guarantee_desc }}</p>
                    <div v-if="plan.product?.elec_guarantee_disc == 1">
                      <p class="text-accent font-bold">{{ plan.product?.elec_guarantee_amt }}</p>
                      <p>{{ plan.product?.elec_guarantee_disctype }}</p>
                    </div>
                    <div v-else class="text-accent font-bold">Discount Not Available</div>
                  </td>
                </tr>
              </tbody>
            </table>

            <!-- gas discounts -->
            <table v-if="plan.plan_type == 'gas'" class="font-poppins table-auto border-collapse border border-slate-400 plan_details_table" width="100%">
              <tbody>
                <!-- Pay on Time -->
                <tr>
                  <td>
                    <p class="font-bold">Pay on time</p>
                    <p>{{ plan.product?.gas_payontime_desc }}</p>
                    <div v-if="plan.product?.gas_payontime_disc == 1">
                      <p class="text-accent font-bold">{{ plan.product?.gas_payontime_amt }}</p>
                      <p>{{ plan.product?.gas_payontime_disctype }}</p>
                    </div>
                    <div v-else class="text-accent font-bold">Discount Not Available</div>
                  </td>
                </tr>
                <!-- Guaranteed -->
                <tr>
                  <td>
                    <p class="font-bold">Guaranteed</p>
                    <p>{{ plan.product?.gas_guarantee_desc }}</p>
                    <div v-if="plan.product?.gas_guarantee_disc == 1">
                      <p class="text-accent font-bold">{{ plan.product?.gas_guarantee_amt }}</p>
                      <p>{{ plan.product?.gas_guarantee_disctype }}</p>
                    </div>
                    <div v-else class="text-accent font-bold">Discount Not Available</div>
                  </td>
                </tr>
              </tbody>
            </table>

          </el-collapse-item>
          <!-- Billing and Payment Details Tab -->
          <el-collapse-item title="Billing and Payment details" name="4">
            <table class="font-poppins table-auto border-collapse border border-slate-400 plan_details_table" width="100%">
              <tbody>
                <tr>
                  <td>
                    <p class="font-bold">Billing Frequency</p>
                    <ul class="text-left">
                      <li class="capitalize">
                        <font-awesome-icon icon="fa-regular fa-circle-check"  class="text-purple" />
                        <span class="text-sm pl-4">
                          {{ plan?.product?.bill_frequency }}
                        </span>
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p class="font-bold">Payment Methods</p>
                    <ul class="text-left lg:grid lg:grid-cols-2">
                      <li>
                        <font-awesome-icon v-if="plan?.product?.payment_method_directdebit == 1" icon="fa-regular fa-circle-check"  class="text-purple" />
                        <font-awesome-icon v-else icon="fa-regular fa-circle-xmark"  class="text-accent" />
                        <span class="text-sm pl-4">Direct Debit</span>
                      </li>
                      <li>
                        <font-awesome-icon v-if="plan?.product?.payment_method_bpay == 1" icon="fa-regular fa-circle-check"  class="text-purple" />
                        <font-awesome-icon v-else icon="fa-regular fa-circle-xmark"  class="text-accent" />
                        <span class="text-sm pl-4">BPAY</span>
                      </li>
                      <li>
                        <font-awesome-icon v-if="plan?.product?.payment_method_creditcard == 1" icon="fa-regular fa-circle-check"  class="text-purple" />
                        <font-awesome-icon v-else icon="fa-regular fa-circle-xmark"  class="text-accent" />
                        <span class="text-sm pl-4">Credit Card</span>
                      </li>
                      <li>
                        <font-awesome-icon v-if="plan?.product?.payment_method_cheque == 1" icon="fa-regular fa-circle-check"  class="text-purple" />
                        <font-awesome-icon v-else icon="fa-regular fa-circle-xmark"  class="text-accent" />
                        <span class="text-sm pl-4">Cheque</span>
                      </li>
                      <li>
                        <font-awesome-icon v-if="plan?.product?.payment_method_postbill == 1" icon="fa-regular fa-circle-check"  class="text-purple" />
                        <font-awesome-icon v-else icon="fa-regular fa-circle-xmark"  class="text-accent" />
                        <span class="text-sm pl-4">Post Bill</span>
                      </li>
                      <li>
                        <font-awesome-icon v-if="plan?.product?.payment_method_byphone == 1" icon="fa-regular fa-circle-check"  class="text-purple" />
                        <font-awesome-icon v-else icon="fa-regular fa-circle-xmark"  class="text-accent" />
                        <span class="text-sm pl-4">By Phone</span>
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>

              
            </table>
            <p class="font-poppins mt-1">*Please review additional fees associated with different payment types in "other fees" tab</p>
          </el-collapse-item>
          <!-- Other Fees Tab -->
          <el-collapse-item title="Other Fees" name="5">
            <div class="font-poppins">
              <ul v-if="plan.distribution_fees" class="text-left">
                <li class="mb-2 flex flex-row justify-start-start" v-for="(item, index) in plan.distribution_fees" :key="index">
                  <font-awesome-icon icon="fa-regular fa-circle-check" class="text-purple mt-1" />
                  <div class="pl-2">
                    <p class="font-bold">{{ item.fee_type }}</p>
                    <p>
                      <span v-if="item.site_visit_fee">${{ item.site_visit_fee }}</span>
                      <span v-if="item.disclaimer"> - {{ item.disclaimer }}</span>
                    </p>
                  </div>
                </li>
                <li v-if="plan?.product?.paper_bill_fee_enabled == 1" class="mb-2 flex flex-row justify-start-start">
                  <font-awesome-icon icon="fa-regular fa-circle-check" class="text-purple mt-1" />
                  <div class="pl-2">
                    <p class="font-bold">Paper Bill Fee</p>
                    <p>{{ plan?.product?.paper_bill_fee_amt }}</p>
                  </div>
                </li>
                <li v-if="plan?.product?.bpay_fee_enabled == 1" class="mb-2 flex flex-row justify-start-start">
                  <font-awesome-icon icon="fa-regular fa-circle-check" class="text-purple mt-1" />
                  <div class="pl-2">
                    <p class="font-bold">BPAY Payment Fee</p>
                    <p>{{ plan?.product?.bpay_fee_amt }}</p>
                  </div>
                </li>
                <li v-if="plan?.product?.cc_fee_enabled == 1" class="mb-2 flex flex-row justify-start-start">
                  <font-awesome-icon icon="fa-regular fa-circle-check" class="text-purple mt-1" />
                  <div class="pl-2">
                    <p class="font-bold">Credit Card Payment Fee</p>
                    <p v-html="plan?.product?.cc_fee_amt.replace(/(?:\r\n|\r|\n)/g, '<br>')"></p>
                  </div>
                </li>
                <li v-if="plan?.product?.dd_dishonesty_fee_enabled == 1" class="mb-2 flex flex-row justify-start-start">
                  <font-awesome-icon icon="fa-regular fa-circle-check" class="text-purple mt-1" />
                  <div class="pl-2">
                    <p class="font-bold">Direct Debit Dishonesty Fee</p>
                    <p>{{ plan?.product?.dd_dishonesty_fee_amt }}</p>
                  </div>
                </li>
                <li v-if="plan?.product?.late_payment_fee_enabled == 1" class="mb-2 flex flex-row justify-start-start">
                  <font-awesome-icon icon="fa-regular fa-circle-check" class="text-purple mt-1" />
                  <div class="pl-2">
                    <p class="font-bold">Late Payment Fee</p>
                    <p>{{ plan?.product?.late_payment_fee_amt }}</p>
                  </div>
                </li>
              </ul>
            </div>
          </el-collapse-item>
          <!-- Contact Details -->
          <el-collapse-item title="Contract Details" name="6">
            <div class="font-poppins">
              <table class="table-auto border-collapse border border-slate-400 plan_details_table" width="100%">
                <tbody>
                  <tr>
                    <td class="font-bold" width="30%">
                      Contract Length
                    </td>
                  </tr>
                  <tr>
                    <td>{{ plan?.product?.contract_plan_length }}</td>
                  </tr>
                  <tr>
                    <td class="font-bold">Privacy Policy</td>
                  </tr>
                  <tr>
                    <td>{{ plan?.retailer?.privacy_policy_url }}</td>
                  </tr>
                  <tr>
                    <td class="font-bold">Market Contract</td>
                    </tr>
                  <tr>
                    <td>{{ plan?.retailer?.terms_conditions_url }}</td>
                  </tr>
                  <tr>
                    <td class="font-bold">Energy Fact Sheet</td>
                  </tr>
                  <tr>
                    <td v-if="plan?.state == 'VIC'">{{ plan?.epfs }}</td>
                    <td v-else>{{ plan?.product?.energy_fact_sheet }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>

      <div class="font-poppins text-xs text-center space-x-4">
        {{ plan?.retailer?.legal_name }}, {{ plan?.retailer?.address }}<br>
        ABN: {{ plan?.retailer?.abn }}
      </div>
    </div>
  </div>
</template>
<style>
.el-collapse {
  @apply border-t-0
}
.el-collapse-item__header {
  @apply font-poppins pl-2 text-lg;
}
.el-collapse-item__content {
  @apply bg-white text-black p-4;
}
.el-tabs__nav {
  @apply w-full border-0;
}
.el-tabs__item {
  @apply  w-1/3 font-bold text-sm;
}
.el-tabs__item.is-active {
  @apply text-white;
}
.el-tabs__nav-wrap::after {
  @apply hidden;
}
.plan_details_table td {
  @apply border p-2;
}
table#rates-container > tbody > tr {
  &:not(:first-child) {
    @apply border-t border-dashed; 
  }
  > td, th {
    @apply py-2;
  }
}

</style>
