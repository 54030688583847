<script setup>
import { ref, onMounted } from "vue";
import { useModalStore } from "@/stores/modals";
import { useRoute } from 'vue-router';

const emit = defineEmits(["select"]);

const modalStore = useModalStore();
const state = ref("");
const addressId = ref(null);
const route = useRoute();

if (route.query.address && route.query.id) {
    state.value = route.query.address;
    addressId.value = window.atob(route.query.id);
    modalStore.setAddress(route.query.address);

    emit("select", {
        value: route.query.address,
        id: window.atob(route.query.id),
        rank:0,
    });
        
    window.focus();
}

const predictiveQuery = (query, cb) => {
    modalStore.setNMIDetails(null); // reset NMI Details

    // flag to search directly on Alinta Meter API
    if ( query.match(/^[0-9]+$/) !== null && query.length == 11) {

        // request alinta access token
        modalStore.setAlintaAccessToken().then(() => {
            let _nmi = query.slice(0, 10);
            let _checksum = query.slice(-1);

            // get NMI details
            modalStore.getNMIDetails(_nmi, _checksum).then((resp) => {

                if (resp !== false) {
                    // prepare NMI address for geoscape search
                    let nmiUtilityAddress = resp.utilityAddress;
                    delete nmiUtilityAddress.deliveryPointIdentifier; // remove delivery point

                    let sortingAddress = [
                        'flatOrUnitType',
                        'flatOrUnitNumber',
                        'lotNumber',
                        'houseNumber',
                        'houseNumberSuffix',
                        'addressLine1',
                        'addressLine2',
                        'streetName',
                        'streetType',
                        'suburbOrPlaceOrLocality',
                        'stateOrTerritory',
                        'postcode'
                    ];

                    // sort the ulitily address
                    let sortedUtilityAddress = Object.keys(nmiUtilityAddress)
                    .sort(function(a, b) {
                        return sortingAddress.indexOf(a) - sortingAddress.indexOf(b);
                    })
                    .map((key) => {
                        return nmiUtilityAddress[key]
                    });

                    // add NMI and NMI checksum to response
                    Object.assign(resp, { nmi: _nmi, checksum: _checksum });
                    modalStore.setNMIDetails(resp);

                    cb([
                        {
                            key: query,
                            value: sortedUtilityAddress.join(' '),
                            nmi: true
                        }
                    ]);
                }

            });

        });

    // search predictive address
    } else {
        geoscape.predictive(query, cb);
    }
}

const selectAddress = (item) => {
    addressId.value = item.id;
    emit("select", item);
    modalStore.setAddress(item.value)
    window.focus();
};

onMounted(() => {
    if (route.query.leadId) {

        if (route.query.address) {
            state.value = route.query.address;
        }

        window.focus();
    }
})
</script>

<template>
    <el-autocomplete
        v-model="state"
        :fetch-suggestions="predictiveQuery"
        @select="selectAddress"
        :debounce="500"
        :trigger-on-focus="true"
        placeholder="Simply start typing your address"
        class="w-full address-search"
    >
        <template #prefix>
            <img src="/icons/ri_search-line.png" alt="Search" />
        </template>
    </el-autocomplete>
</template>

<style lang="scss">
    .address-search .el-input__wrapper {
        border: 2px solid #F3F5F7;
        border-radius: 47px;
    }
</style>
