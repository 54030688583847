<script setup>
import { ref, watch, computed } from "vue";
import { storeToRefs } from "pinia";
import { useModalStore } from "@/stores/modals";

const modal = useModalStore();
const { showFilterModal, showElec, showGas } = storeToRefs(modal);

const filterSame = ref(false);
const filterAust = ref(false);
const filterCarb = ref(false);
const filterQuarterly = ref(false);
const filterPostal = ref(false);

function setFilter() {
  // Use a local variable to build the filter array to avoid triggering reactive updates immediately
  const newFilter = [];

  console.debug(`FilterModal : Filter status changed to: filterSame : ${filterSame.value}`);
  console.debug(`FilterModal : Filter status changed to: showElec : ${showElec.value}`);
  console.debug(`FilterModal : Filter status changed to: showGas : ${showGas.value}`);

  // Filter for retailer ID matching
  if (filterSame.value && showElec.value && showGas.value) {
    newFilter.push((d) => {
      console.debug(`FilterModal : Filter status changed to: d : `, d);
      return d.elec?.retailer_id === d.gas?.retailer_id;
    });
  }

  // Filter for Australian-owned retailers
  if (filterAust.value) {
    if (showElec.value) {
      newFilter.push((d) => d.elec?.retailer?.aus_owned === true);
    }
    if (showGas.value) {
      newFilter.push((d) => d.gas?.retailer?.aus_owned === true);
    }
  }

  // Filter for carbon-neutral retailers
  if (filterCarb.value) {
    if (showElec.value) {
      newFilter.push((d) => d.elec?.retailer?.carbon_neutral === true);
    }
    if (showGas.value) {
      newFilter.push((d) => d.gas?.retailer?.carbon_neutral === true);
    }
  }

  // Filter for quarterly billing support
  if (filterQuarterly.value) {
    if (showElec.value) {
      newFilter.push((d) => d.elec?.retailer?.allow_quarterly_billing === true);
    }
    if (showGas.value) {
      newFilter.push((d) => d.gas?.retailer?.allow_quarterly_billing === true);
    }
  }

  // Filter for postal billing support
  if (filterPostal.value) {
    if (showElec.value) {
      newFilter.push((d) => d.elec?.retailer?.allow_postal_billing === true);
    }
    if (showGas.value) {
      newFilter.push((d) => d.gas?.retailer?.allow_postal_billing === true);
    }
  }

  // Apply the new filter in a way that avoids unnecessary reactivity
  modal.filter = newFilter;

  // Call showFuel without triggering reactive watchers
  modal.showFuel(showElec.value, showGas.value);
}


// function setFilter() {
//   modal.filter = [];
//   console.debug(`FilterModal : Filter status changed to: filterSame : ${filterSame.value}`);
//   console.debug(`FilterModal : Filter status changed to: showElec : ${showElec.value}`);
//   console.debug(`FilterModal : Filter status changed to: showGas : ${showGas.value}`);

//   if (filterSame.value && showElec.value && showGas.value) {
//     modal.filter.push(function (d) {
//       console.debug(`FilterModal : Filter status changed to: d : `,d);
//       return d.elec.retailer_id == d.gas.retailer_id;
//     });
//   }

//   if (filterAust.value) {
//     if (showElec.value) {
//       modal.filter.push(function (d) {
//         return d.elec?.retailer?.aus_owned == true ?? 1;
//       });
//     }
//     if (showGas.value) {
//       modal.filter.push(function (d) {
//         return d.gas?.retailer?.aus_owned == true ?? 1;
//       });
//     }
//   }

//   if (filterCarb.value) {
//     if (showElec.value) {
//       modal.filter.push(function (d) {
//         return d.elec?.retailer?.carbon_neutral == true ?? 1;
//       });
//     }
//     if (showGas.value) {
//       modal.filter.push(function (d) {
//         return d.gas?.retailer?.carbon_neutral == true ?? 1;
//       });
//     }
//   }

//   if(filterQuarterly.value) {
//     if (showElec.value) {
//       modal.filter.push(function (d) {
//         return d.elec?.retailer?.allow_quarterly_billing == true ?? 1;
//       });
//     }
//     if (showGas.value) {
//       modal.filter.push(function (d) {
//         return d.gas?.retailer?.allow_quarterly_billing == true ?? 1;
//       });
//     }
//   }

//   if(filterPostal.value) {
//     if (showElec.value) {
//       modal.filter.push(function (d) {
//         return d.elec?.retailer?.allow_postal_billing == true ?? 1;
//       });
//     }
//     if (showGas.value) {
//       modal.filter.push(function (d) {
//         return d.gas?.retailer?.allow_postal_billing == true ?? 1;
//       });
//     }
//   }

//   // FIXME: should be able to just call showPair?
//   modal.showFuel(showElec, showGas);
// }

watch(showElec, setFilter);
watch(showGas, setFilter);

watch(filterSame, setFilter);
watch(filterAust, setFilter);
watch(filterCarb, setFilter);
watch(filterQuarterly, setFilter);
watch(filterPostal, setFilter);

// const filters = computed(() => ({
//   showElec: showElec.value,
//   showGas: showGas.value,
//   filterSame: filterSame.value,
//   filterAust: filterAust.value,
//   filterCarb: filterCarb.value,
//   filterQuarterly: filterQuarterly.value,
//   filterPostal: filterPostal.value,
// }));

// watch(filters, () => {
//   setFilter();
// }, { deep: true });

// watch(
//   () => [
//     showElec.value,
//     showGas.value,
//     filterSame.value,
//     filterAust.value,
//     filterCarb.value,
//     filterQuarterly.value,
//     filterPostal.value,
//   ],
//   () => {
//     setFilter();
//   },
// );


</script>
<template>
  <div
    v-show="showFilterModal"
    class="fixed h-screen w-full bg-black bg-opacity-60 z-20 top-0 left-0"  @click="showCallModal = false"
  >
    <div
      class="mx-auto mt-20 z-30 bg-white text-dark rounded-xl shadow-lg p-8 w-64 border border-gray-100 space-y-4"
    >
      <p class="mb-2 font-bold">Filter By</p>
      <div class="flex flex-col space-y-1">
        <label
          ><input type="checkbox" v-model="showElec" /> Show Electricity
          Plans</label
        >
        <label
          ><input type="checkbox" v-model="showGas" /> Show Gas Plans</label
        >
        <label
          ><input type="checkbox" v-model="filterSame" /> Same retailer</label
        >
        <label
          ><input type="checkbox" v-model="filterAust" /> Australian Customer Service</label
        >
        <label
          ><input type="checkbox" v-model="filterQuarterly" /> Allow Quarterly Billing</label
        >
        <label
          ><input type="checkbox" v-model="filterPostal" /> Allow Postal Billing </label
        >
        <!-- <label
          ><input type="checkbox" v-model="filterCarb" /> Carbon Neutral</label
        > -->
      </div>
      <div class="flex justify-center space-x-4">
        <button
          class="bg-blue text-white font-bold shadow-lg rounded-full py-1.5 px-4 w-full"
          @click="showFilterModal = false"
        >
          Apply
        </button>
      </div>
    </div>
  </div>
</template>
