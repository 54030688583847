<script setup>
import { storeToRefs } from "pinia";
import { useModalStore } from "@/stores/modals";

const { showCallModal } = storeToRefs(useModalStore());
const base = import.meta.env.BASE_URL;

</script>
<template>
  <div
    v-show="showCallModal"
    class="fixed h-screen w-full bg-black bg-opacity-40 z-20 top-0 left-0"  @click="showCallModal = false"
  >
    <div
      class="mx-auto mt-60 z-30 bg-blue text-white rounded-xl shadow-lg p-4 w-64 border border-gray-100 text-center space-y-4"
    >
	 <div class="flex justify-end space-x-4 w-full">
        <p
          class="text-sm text-white"
          @click="showCallModal = false"
        >
            <img
            class="h-6 inline-block"
            :src="base + 'icons/' + 'circle-xmark-solid.png'"
          />
        </p>
        
      </div>
      <p class="text-white text-md">Click to speak to a specialist:</p>
      <a href="tel:1300754155" class="text-lg font-bold block mt-4">1300 754 155</a>
     
    </div>
  </div>
</template>
