<script setup>
import AddressFrom from "@/components/AddressSearch.vue";
import LayoutDefault from '@/layouts/LayoutDefault.vue';
import { storeToRefs } from "pinia";
import { useModalStore } from "@/stores/modals";
import { useRoute } from "vue-router";

const { showInfoPopup } = storeToRefs(useModalStore());
const base = import.meta.env.BASE_URL;
const route = useRoute();

function toggle() {
    showInfoPopup.value = !showInfoPopup.value;
}
</script>

<template>
    <layout-default>
        <div class="h-full w-full py-4 lg:px-4 xl:px-16 flex flex-col">
            <img class="mx-auto h-10 mb-xl" :src="base + 'images/comparable-logo.png'" alt="Comparable" />
            <h2 class="mx-4">Please Confirm your Address</h2>
            
            <AddressFrom />
        
            <div class="p-4 mt-4 bg-gray-0 rounded-lg border-2 border-gray-0 flex flex-row gap-4 mx-4">
                <div class="flex-none">
                    <img src="/icons/ri_information-fill.png" class="w-8" />
                </div>
                <div>
                    <p class="text-justify lg:text-left">
                        <b>Important:</b> By entering your supply address details you consent to us 
                        looking up this information in Australian Energy Database so we can give you 
                        products and pricing information most relevant to you and to set up or 
                        transfer your energy supply. 
                    </p>
                </div>
            </div>
        </div>
    </layout-default>
</template>
