<script setup>
import { onMounted } from "vue";
import { useModalStore } from "@/stores/modals";
import { useRoute } from "vue-router";

const data = useModalStore();
const route = useRoute();

onMounted(() => {
    if (typeof route.query.agt !== "undefined" && route.query.agt == 1) {
        data.setAgent(true);
    }

    if (typeof route.query.enc !== "undefined" && route.query.enc == 1) {
        data.encryptedLead = true;
    }

    if (typeof route.query.add_EN !== "undefined" && route.query.add_EN == 1) {
        data.setAddEnergyAccount(true);
    }

    if (typeof route.query.zh_cont !== "undefined") {
        data.setContactId(route.query.zh_cont);
    }

    if (typeof route.query.leadId !== "undefined") {
        data.setLeadId(route.query.leadId);
    }

    if (typeof route.query.signature !== "undefined") {
        data.setContactSignature(route.query.signature);
    }
});
</script>
<template>
    <div class="container grid grid-cols-1 pb-16">
        <div class="column flex flex-col xl:flex-row">
            <div class="content-block h-64 hidden xl:block xl:h-auto xl:max-h-865px flex flex-none relative text-center">
                <h3 class="text-3xl font-bold text-white absolute z-10 font-bold inset-x-px mt-32 mx-md font-quicksand">Find your offer!</h3>
                <img class="w-full object-cover object-top relative z-0" src="/images/Search-Banner.jpg" />
            </div>
            <div class="w-full relative content-block pt-lg flex flex-col items-center">
                <slot />
            </div>
        </div>
    </div>
</template>