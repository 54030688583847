import { createApp } from "vue";
import { createPinia } from "pinia";

import ElementPlus from "element-plus";
import TabsPaneContext from "element-plus";
import "element-plus/dist/index.css";
import "./index.css";
import VueJsTour from '@globalhive/vuejs-tour';
import '@globalhive/vuejs-tour/dist/style.css';

import App from "./App.vue";
import router from "./router";

/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";

/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

/* import specific icons */
import {
  faMagnifyingGlass,
  faSortDown,
  faSortUp,
  faCircleInfo,
  faCircleQuestion,
  faFilter,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";

/* import specific icons */
import {
  faCircleCheck,
  faCircleXmark
} from "@fortawesome/free-regular-svg-icons";

const ver = import.meta.env.VITE_VSTR ?? "<NOT SET>";
console.log(`Comparable\\Comparitor ${ver}`);

/* add icons to the library */
library.add(faMagnifyingGlass);
library.add(faSortDown);
library.add(faSortUp);
library.add(faCircleInfo);
library.add(faCircleQuestion);
library.add(faFilter);
library.add(faCircleCheck);
library.add(faCircleXmark);
library.add(faArrowRight);


const app = createApp(App);

app.config.devtools = true;

app.use(createPinia());
app.use(router);
app.use(ElementPlus);
app.use(TabsPaneContext);
app.use(VueJsTour);

app.component("font-awesome-icon", FontAwesomeIcon);

app.mount("#app");
