export const distmap = {
  ACTEWP: "Evoenergy",
  "AEVO-ZACT": "Evoenergy Gas - Evoenergy ACT",
  AURORAP: "TasNetworks",
  CITIPP: "CitiPower",
  CNRGYP: "Essential Energy",
  EASTERN: "AusNet Services",
  ENERGEXP: "Energex",
  ENERGYAP: "Ausgrid",
  INTEGP: "Endeavour Energy",
  "NAGN-ADEL": "Australian Gas Networks - AGN Adelong, Gundagai and Tumut",
  "NAGN-ALBY": "Australian Gas Networks - AGN Albury",
  "NAGN-BOCO": "Australian Gas Networks - AGN Bombala and Cooma",
  "NAGN-MURR": "Australian Gas Networks - AGN Murray Valley (NSW)",
  "NAGN-NORT": "Australian Gas Networks - AGN North (NSW)",
  "NAGN-TECH":
    "Australian Gas Networks - AGN Temora, Culcairn, Holbrook, Henty and Walla Walla",
  "NAGN-WAWA": "Australian Gas Networks - AGN Wagga Wagga",
  "NALL-ZNSW": "Allgas Energy - Allgas Energy NSW",
  "NAPA-CTRR": "APA Group - Central Ranges Pipeline Tamworth",
  "NEVO-QUEN": "Evoenergy Queanbeyan - Evoenergy Queanbeyan",
  "NEVO-SHOA": "Evoenergy Gas - Evoenergy Shoalhaven",
  "NJEM-CAPT": "Jemena Gas - Jemena - Capital Region",
  "NJEM-COST": "Jemena Gas - Jemena Coastal Network",
  "NJEM-CTRY": "Jemena Gas - Jemena Country Network",
  POWCP: "Powercor Australia",
  "QAGN-BRIR": "Australian Gas Networks - AGN - Brisbane and Riverview",
  "QAGN-NORT": "Australian Gas Networks - AGN - Northern",
  "QAGN-WIDE": "Australian Gas Networks - AGN - Wide Bay (Unregulated)",
  "QALL-ZQLD": "Allgas Energy - Allgas Energy QLD",
  "SAGN-GAMB": "Australian Gas Networks - AGN Mount Gambier",
  "SAGN-METB": "Australian Gas Networks - AGN Metro/Barossa/Peterborough",
  "SAGN-PPRI": "Australian Gas Networks - AGN Port Pirie",
  "SAGN-RIVR": "Australian Gas Networks - AGN Riverland/Murray Bridge",
  "SAGN-WHYA": "Australian Gas Networks - AGN Whyalla",
  SOLARISP: "Jemena Electricity Networks",
  UMPLP: "SA Power Networks",
  UNITED: "United Energy Distribution",
  VAGN: "Australian Gas Networks",
  "VAGN-BARN": "Australian Gas Networks - Bairnsdale",
  "VAGN-CNTR": "Australian Gas Networks - Central",
  "VAGN-MILD": "Australian Gas Networks - Mildura",
  "VAGN-MURR": "Australian Gas Networks - Murray",
  "VAGN-NORT": "Australian Gas Networks - North",
  "VGNV-VGNV": "Gas Networks Victoria - Gas Networks Victoria",
  VMUL: "Multinet",
  "VMUL-MMET": "Multinet - Melbourne Metropolitan Area",
  "VMUL-SGIP": "Multinet - South Gippsland Extension",
  "VMUL-YARR": "Multinet - Yarra Valley Gas Extension",
  VNET: "AusNet Services",
  "VNET-ACEN": "AusNet Services (gas) - Adjoining Central",
  "VNET-AWST": "AusNet Services (gas) - Adjoining West",
  "VNET-CNTR": "AusNet Services (gas) - Central",
  "VNET-WEST": "AusNet Services (gas) - West",
};
