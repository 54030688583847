<script setup>
import { ref } from "vue";
import { storeToRefs } from "pinia";
import { useModalStore } from "@/stores/modals";

import { camelCase } from "lodash";

const modalStore = useModalStore();
const { planTier, showPlanModal, plan, tariff, estimate, showEligibilityModal } = storeToRefs(modalStore);
const base = import.meta.env.BASE_URL;
const activeName = ref("1");


const getPlanEligibilityList = (eligilibility) => {

  return eligilibility.split(/\r?\n/);
}; 
</script>
<template>
  <div v-if="plan && showEligibilityModal" class="fixed h-screen w-full bg-black bg-opacity-40 z-20 top-0 left-0">
    <div class="h-auto max-h-680px lg:w-3/6 xl:w-1/5 mx-4 lg:mx-auto overflow-auto z-30  px-3 pt-4 pb-8 rounded-xl border border-gray-2 text-left space-y-4 bg-white mt-10">
      <div class="top-control pb-1 text-center relative">
	      <a class="absolute top-0 right-0 cursor-pointer uppercase font-bold py-1.5 pl-4 text-sm" @click="showEligibilityModal = false" >
          <img class="h-6 inline-block" :src="base + 'icons/' + 'circle-xmark-solid.png'" />
        </a>
      </div>
      <div class="grid grid-cols-1 py-2 text-left">
        <h1 class="text-center my-3 font-bold text-xl">Plan Eligibility</h1>
        <p class="mb-3">The plan {{ plan?.product?.product_name }} by {{ plan?.retailer?.retailer_name }} requires:</p>
        <ul class="px-2">
          <li v-for="item in getPlanEligibilityList(plan.product?.eligibility)">
            <font-awesome-icon icon="fa-2x fa-regular fa-circle-check" class="text-purple" />
            <span class="text-xl pl-1">{{ item.replace('*', '')  }}</span>
          </li>
        </ul>
      </div>

    </div>
  </div>
</template>
<style>
.el-collapse {
  @apply border-t-0
}
.el-collapse-item__header {
  @apply font-poppins pl-2 text-lg;
}
.el-collapse-item__content {
  @apply bg-white text-black p-4;
}
.el-tabs__nav {
  @apply w-full border-0;
}
.el-tabs__item {
  @apply  w-1/3 font-bold text-sm;
}
.el-tabs__item.is-active {
  @apply text-white;
}
.el-tabs__nav-wrap::after {
  @apply hidden;
}
.plan_details_table td {
  @apply border p-2;
}
table#rates-container > tbody > tr {
  &:not(:first-child) {
    @apply border-t border-dashed; 
  }
  > td, th {
    @apply py-2;
  }
}

</style>
