<script setup>
import { ref } from "vue";
import { storeToRefs } from "pinia";
import { useComparatorStore } from "@/stores/comparator.js";
import { Search, Message } from "@element-plus/icons-vue";
import { useModalStore } from "@/stores/modals";
import LayoutDefault from '@/layouts/LayoutDefault.vue';

const websiteUrl = import.meta.env.VITE_COMPARABLE_SITE_URL;


var isError = ref(false);
var isExpand = ref(false);
var isLoading = ref(false);
var showManual = ref(false);
var showContact = ref(false);
var showRequiredMessage = ref(false);
var addressList = ref([]);
var contact = ref({});
var cs_message_empty = "Apologies. It seems your address isn't coming up with any matches in the Australia Energy database.";
var cs_message = "Please enter your details below so one of our Energy experts can call you and offer you a better deal over the phone.";
var cs_message_manual = "Still can't find your address?";

const { address } = storeToRefs(useComparatorStore());
const modalStore = useModalStore();

/* Search a manul address in msats */
function getManualAddress(addr) {
    var addr = JSON.stringify(addr);
    
    if (addr == '{}') {
        showRequiredMessage.value = true;
    } else {
        isLoading.value = true;
        comparable.address(addr, listAddress, onError);
    }
}

/* List addresses returned back from MSATS */
function listAddress(list) {
    document.activeElement.blur();
    isExpand.value = true;
    isLoading.value = false;

    if (list.length > 0) {
        addressList.value = list;
        showManual.value = true;
    } else {
        // isExpand.value = false;
        // showManual.value = true;
        redirectToForm();
    }

    if (showManual.value) {
        showContact.value = true;
    }
    window.focus();
}

function onError() {
    // isError.value = true;
    // isLoading.value = false;
    // isExpand.value = false;
    // showContact.value = true;
    // document.activeElement.blur();
    // window.focus();
    redirectToForm();
}

const redirectToForm = (withParams = true) => {
  
  let url = `${websiteUrl}contact-us/`;

  if (withParams) {
    url += '?address=not-listed';
  }

  window.location.href = url;
};

function splitAddress(str) {
  const seg = str.split(", ");
  return [seg.slice(0, -3).join(", "), seg.slice(-3).join(", ")];
}

function openAddress(item) {
    modalStore.setAddressState(address.value.state_territory)
    modalStore.setStateTerritory(address.value.state_territory)
    modalStore.setFormattedAddress(address.value.formatted_address)
    router.push({
        name: "plans",
        params: { 
        postcode: address.value.postcode, 
        ref: item.details,
        addressidentifier: address.value.address_identifier,
        state: address.value.state_territory,
        address: address.value.formatted_address,
        },
    });
}
</script>

<template>
    <layout-default>

        <el-form v-if="!isError" :model="address" label-width="120px" label-position="top">
            <p v-if="!showRequiredMessage" class="mt-lg lg:mt-xl mb-md">
                Looks like we couldn't find an exact match for your address, let's
                try a different approach.
            </p>

            <p class="text-accent mt-lg lg:mt-xl mb-md" v-else>
                Please add your details below to continue searching.
            </p>

            <el-row :gutter="4">
                <el-col :span="20" :xs="15">
                    <el-form-item label="Property Name">
                        <el-input v-model="address.site_name" />
                    </el-form-item>
                </el-col>
                <el-col :span="4" :xs="9">
                    <el-form-item label="Lot Identifier">
                        <el-input v-model="address.lot_identifier" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="4">
                <el-col :span="4" :xs="10">
                    <el-form-item label="Level Number">
                        <el-input v-model="address.complex_level_number" />
                    </el-form-item>
                </el-col>
                <el-col :span="8" :xs="14">
                    <el-form-item label="Level Type">
                        <el-input v-model="address.complex_level_type" />
                    </el-form-item>
                </el-col>
                <el-col :span="4" :xs="10">
                    <el-form-item label="Unit Identifier">
                        <el-input v-model="address.unit_identifier" />
                    </el-form-item>
                </el-col>
                <el-col :span="8" :xs="14">
                    <el-form-item label="Unit Type">
                        <el-input v-model="address.flatOrUnitType" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="4">
                <el-col :span="5" :xs="10">
                    <el-form-item label="Street Number">
                        <el-row :gutter="4">
                            <el-col :span="10">
                                <el-input v-model="address.street_number_1" />
                            </el-col>
                            <el-col :span="2">
                                <span class="text-gray-500">-</span>
                            </el-col>
                            <el-col :span="10">
                                <el-input v-model="address.street_number_2" />
                            </el-col>
                        </el-row>
                    </el-form-item>
                </el-col>
                <el-col :span="11" :xs="14">
                    <el-form-item label="Street Name">
                        <el-input v-model="address.street_name" />
                    </el-form-item>
                </el-col>
                <el-col :span="4" :xs="16">
                    <el-form-item label="Street Type">
                        <el-input v-model="address.street_type" />
                    </el-form-item>
                </el-col>
                <el-col :span="4" :xs="8">
                    <el-form-item label="Street Suffix">
                        <el-input v-model="address.street_suffix" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="4">
                <el-col :span="16" :xs="24">
                    <el-form-item label="Suburb">
                        <el-input v-model="address.locality_name" />
                    </el-form-item>
                </el-col>
                <el-col :span="4" :xs="16">
                    <el-form-item label="State">
                        <el-input v-model="address.state_territory" />
                    </el-form-item>
                </el-col>
                <el-col :span="4" :xs="8">
                    <el-form-item label="Postcode">
                        <el-input v-model="address.postcode" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="4" justify="end">
                <el-col :span="4" :xs="8">
                    <el-form-item>
                        <el-button
                            style="width: 100%"
                            type="primary"
                            :icon="Search"
                            :disable="isLoading"
                            @click="getManualAddress(address)"
                            >Search</el-button
                        >
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row class="w-full" :gutter="8">
                <el-col :span="24">
                    <el-progress
                        v-show="isLoading"
                        :percentage="10"
                        :indeterminate="true"
                        :text-inside="true"
                    />
                </el-col>
            </el-row>

        </el-form>

        <div v-if="isExpand" style="width: 100%;max-width: 816px;">
            <el-divider content-position="left" style="margin-bottom: 8px; color: var(--el-text-color-secondary)">
                <b>Select your address</b>
            </el-divider>
            <el-table
                :data="addressList"
                @row-click="openAddress"
                :show-header="false"
                style="width: 100%"
                max-height="50vh"
            >
                <el-table-column prop="address" label="Address" max-width="500">
                    <template #default="scope" @click="openAddress(scope.row)">
                    <el-row style="margin-bottom: 2px">
                        <el-col>
                        {{
                            splitAddress(scope.row.address)[0] +
                            " " +
                            splitAddress(scope.row.address)[1]
                        }}
                        </el-col>
                    </el-row>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <div v-if="showContact" style="width: 100%;max-width: 816px;">
            <el-form class="w-full mt-lg lg:mt-xl" :model="contact" label-width="120px" label-position="top">
                <el-row class="mb-lg">
                    <el-col>
                        <el-alert
                        :title="isExpand ? cs_message_manual : cs_message_empty"
                        :description="cs_message"
                        :type="isExpand ? 'info' : 'warning'"
                        :closable="false"
                        show-icon
                        />
                    </el-col>
                </el-row>
                <el-row :gutter="4">
                    <el-col :span="8" :xs="24">
                        <el-form-item label="&nbsp;">
                            <el-button
                                style="width: 100%"
                                type="primary"
                                :icon="Message"
                                :disable="isLoading"
                                @click="redirectToForm(false)"
                                >Contact Us</el-button
                            >
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </div>
            
    </layout-default>
</template>

<style lang="scss" scoped>
.el-row {
    margin-bottom: 4px;
}

.el-form-item {
    margin-bottom: 4px;
    white-space: nowrap;
    overflow: hidden;
}

.el-form-item * {
  margin-bottom: 2px;
}

.el-table.is-scrolling-left
  .el-table-fixed-column--right.is-first-column::before {
  box-shadow: none;
}
</style>
