<script setup>
import { onMounted } from "vue";
import { useModalStore } from "@/stores/modals";
import { useRoute } from "vue-router";

const data = useModalStore();
const route = useRoute();
// Access environment variable
const websiteUrl = import.meta.env.VITE_COMPARABLE_SITE_URL;

// Build the logo URL dynamically
const logoUrl = `${websiteUrl}/wp-content/uploads/2022/11/logo-full-color.png`;


onMounted(() => {
    if (typeof route.query.agt !== "undefined" && route.query.agt == 1) {
        data.setAgent(true);
    }

    if (typeof route.query.enc !== "undefined" && route.query.enc == 1) {
        data.encryptedLead = true;
    }

    if (typeof route.query.add_EN !== "undefined" && route.query.add_EN == 1) {
        data.setAddEnergyAccount(true);
    }

    if (typeof route.query.zh_cont !== "undefined") {
        data.setContactId(route.query.zh_cont);
    }

    if (typeof route.query.leadId !== "undefined") {
        data.setLeadId(route.query.leadId);
    }

    if (typeof route.query.signature !== "undefined") {
        data.setContactSignature(route.query.signature);
    }
});
</script>
<template>
    <header id="header" class="z-50 bg-gray-0 py-4" role="banner" style="position: sticky;">
        <div class="container mx-auto px-4">
            <a :href="websiteUrl">
                <img class="logo" :src="logoUrl" alt="Comparable">
            </a>
        </div>
    </header>
    <slot />
    
</template>
<style>
    #header .logo {
        width: 190px;
    }
</style>