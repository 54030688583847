<script setup>
import AddressFrom from "@/components/AddressSearch.vue";
import LayoutComparable from '@/layouts/LayoutComparable.vue';
import { storeToRefs } from "pinia";
import { useModalStore } from "@/stores/modals";
import { useRoute } from "vue-router";

const { showInfoPopup } = storeToRefs(useModalStore());
// Access the base URL from the environment variable
const base = import.meta.env.BASE_URL;
const websiteUrl = import.meta.env.VITE_COMPARABLE_SITE_URL;
const route = useRoute();

function toggle() {
    showInfoPopup.value = !showInfoPopup.value;
}


// Define the image paths relative to the base URL
const partners = [
    "/wp-content/uploads/2022/12/alintaenergy.png",
    "/wp-content/uploads/2022/12/dodo-power-gas.png",
    "/wp-content/uploads/2024/05/ENGIE.svg",
    "/wp-content/uploads/2022/12/actewagl.png",
    "/wp-content/uploads/2022/12/1st-energy.png",
    "/wp-content/uploads/2022/12/tango.png"
];

const googleRating = `${websiteUrl}/wp-content/uploads/2024/05/xa-vive-Google-review-5.0-rating.png.webp`;

</script>

<template>
    <layout-comparable>
        <div class="to-violet from-navyBlue bg-gradient-to-r bg-center pb-lg pt-lg">
            <div class="container grid grid-cols-1">
                <div class="column">
                    <div class="content-block lg:w-2/3 lg:mx-auto">
                        <h1 class="text-center text-white text-lg lg:text-xl lg:text-2xl"> 
                            Hi {{ route.query.name ?? 'there' }}, we are glad you are here!
                        </h1> 
                        <p class="text-center text-white text-lg lg:text-xl lg:text-2xl">
                            You are two clicks away from seeing some great energy plans that are available at your address.
                            Please confirm your address below and unlock your savings!
                        </p>
                    </div> 
                </div> 
                <div class="column w-full max-w-4xl mx-auto"> 
                    <div class="content-block text-center address-form-container"> 
                        <AddressFrom />
                    </div> 
                </div> 
                <div class="column"> 
                    <div class="content-block"> 
                        <p class="text-white text-justify lg:text-center text-sm max-w-4xl mx-auto mb-md">
                            <b>Important:</b> By entering your supply address details you consent to us 
                            looking up this information in Australian Energy Database so we can give you 
                            products and pricing information most relevant to you and to set up or 
                            transfer your energy supply. 
                        </p>
                    </div> 
                </div> 
            </div> 
        </div>
        <div class="section py-lg bg-lighter">
            <div class="container grid grid-cols-1">
                <div class="column">
                    <div class="content-block flex flex-wrap flex-row justify-center items-center gap-lg">
                        <div class="w-full lg:w-auto lg:flex-auto text-center lg:text-left text-md lg:text-xl leading-tight uppercase">
                            Our customers say
                        </div>
                        <div class="flex-1 lg:flex-auto text-md lg:text-lg leading-tight uppercase font-bold">
                            EXCELLENT
                        </div>
                        <img decoding="async" class="flex-1 lg:flex-auto w-6 lg:w-16" :src="googleRating">
                        <div class="w-full lg:w-auto lg:flex-auto text-md lg:text-lg text-center leading-tight uppercase">
                            based on 1,200+ reviews
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section py-lg">
            <div class="container grid grid-cols-1">
                <div class="column">
                    <div class="content-block">
                        <h3 class="text-2xl lg:text-3xl font-bold text-secondary text-center mb-md">Our partners</h3>
                        <div class="flex flex-row flex-wrap justify-center items-center">
                            <div
                                v-for="(path, index) in partners"
                                :key="index"
                                class="w-1/3 lg:w-auto px-md"
                            >
                                <img class="w-32" :src="`${websiteUrl}${path}`" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </layout-comparable>
</template>
<style>
.address-form-container {
    .not-listed, h2 {
        color: white;
    }
}

.address-form-container {
    .search-result {
        background: transparent;
        margin-bottom: 0;
        table {
            tr {
                background: transparent;
                td {
                    .cell {
                        background: rgba(255, 255, 255, .80);
                    }
                }
            }
        }
    }
}
</style>
