import { defineStore } from "pinia";

export const useComparatorStore = defineStore({
  id: "comparator",
  state: () => ({
    address: {},
  }),
  getters: {},
  actions: {},
});
