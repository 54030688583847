<script setup>
import { computed, ref } from "vue";
import { storeToRefs } from "pinia";
import { useModalStore } from "@/stores/modals";
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.css';
import _ from "lodash";

const modalStore = useModalStore();
const { custType, showUsagePopup, showElec, showGas, usage, concession, hasGas } = storeToRefs(modalStore);
const base = import.meta.env.BASE_URL;

const fMap = {
  GENERAL_USAGE: "General Usage",
  PEAK_USAGE: "Peak Usage",
  OFF_PEAK_USAGE: "Off Peak Usage",
  TOU_SHOULDER: "Shoulder",
  CONTROLLED_LOAD: "Controlled Load",
  CONTROLLED_LOAD_1: "Controlled Load 1",
  CONTROLLED_LOAD_2: "Controlled Load 2",
  SUMMER_DEMAND: "Summer Demand",
  NON_SUMMER_DEMAND: "Non-Summer Demand",
  CLIMATE_SAVER: "Climate Saver",
  SOLAR: "Solar Feed-in",
};

// const cMap = {
//   ELEC: { name: "Electricity Concession" },
//   MEDCOOL: { name: "Medical Cooling Concession" },
//   LIFE: { name: "Life Support Concession" },
//   CONTLOAD: { name: "Controlled Load Concession" },
//   STPCC: { name: "Service to Property Charge Concession" },
//   WINTERGAS: { name: "Winter Gas Concession" },
//   GASREBATE: { name: "Gas Concession Rebate" },
// };

// electricity concessions
const cMap = {
  ELEC: { name: "Electricity Concession" },
  MEDCOOL: { name: "Medical Cooling Concession" },
};

// gas concessions
const cMapGas = {
  WINTERGAS: { name: "Winter Gas Concession" },
  GASREBATE: { name: "Gas Concession Rebate" },
};

const fields = computed(() => {
  var f = [];
  for (const i of modalStore.usageInput) {
    f.push({ label: fMap[i], name: i });
  }

  return f;
});

const activeName = ref('electricity_tab');

function getValue(name) {
  if (usage.value[name] != null) {
    return usage.value[name];
  } else {
    return null;
  }
}

function setValue(e) {
  usage.value[e.target.id] = e.target.value;
}

function runCalc() {
  usage.value['elec_concessions'] = selectedElecConcession;
  usage.value['gas_concessions'] = selectedGasConcession;
  usage.value['state'] = modalStore.state
  usage.value['customer_type'] = custType;
  modalStore.setUserUsage();
}

const selectedElecConcession = ref(null);
const selectedGasConcession = ref(null);
const cOptionsElec = Object.keys(cMap).map(key => ({
    id: key,
    name: cMap[key].name
  })
);

const stateLowerCase = modalStore.state.toLowerCase();
let cOptionsGas = [];
if (stateLowerCase === 'vic') {
  cOptionsGas = [
    { id: 'WINTERGAS', name: cMapGas['WINTERGAS'].name }
  ];
} else if (stateLowerCase === 'nsw' || stateLowerCase === 'qld') {
  cOptionsGas = [
    { id: 'GASREBATE', name: cMapGas['GASREBATE'].name }
  ];
}

const calculateBtnText = computed(() => {
  if (modalStore.isCalculating) return 'Calculating your savings...';

  return 'Calculate';
});
const calculateBtnColor = computed(() => {
  if (modalStore.isCalculating) return 'bg-accent';

  return 'bg-blue';
});
// const cOptionsGas = Object.keys(cMapGas).map(key => ({
//     id: key,
//     name: cMapGas[key].name
//   })
// );
</script>

<template>
  <div v-if="showUsagePopup" class="fixed h-full w-full bg-black bg-opacity-40 z-20 top-0 left-0">
    <div
      class="mt-4 lg:mx-auto mx-4 lg:w-full lg:max-w-4xl max-h-[85%] overflow-auto z-30 bg-white text-dark rounded-xl shadow-lg px-8 pt-4 pb-8 border border-gray-100 space-y-4">

      <div class="flex flex-wrap mb-4">
        <div class="w-full">
          <div class="grid grid-cols-2 border-b-2 mb-4">
            <h3 class="text-xl font-bold text-accent mt-4 uppercase">Enter Your Existing Bill Below</h3>
            <button class="py-1.5 text-right" @click="showUsagePopup = false">
              <img class="h-6 inline-block" :src="base + 'icons/' + 'circle-xmark-solid.png'" />
            </button>
            <p class="text-sm text-primary my-4 col-span-full">Entering your bill below will provide you with an estimate
              of how much you will save by switching to a new plan. The estimate is not a reflection of the total cost of
              the new plan and can only calculate the estimate based on the information you provide and does not consider
              the increase in rates or increase in your usage.</p>
          </div>

          <el-collapse v-model="activeName" accordion>
            <!-- Electricity tab -->
            <el-collapse-item v-if="showElec" title="Electricity" name="electricity_tab">
              <div class="mb-4">
                <label class="block text-gray-700 font-bold md:text-right mb-1 md:mb-0 pr-4" for="elec_start">
                  Bill Start Date
                </label>
                <input
                  class="appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-800 leading-tight focus:outline-none focus:bg-white focus:border-primary"
                  id="elec_start" type="date" @update="setValue" @change="setValue" :value="getValue('elec_start')" />
              </div>
              <div class="mb-4">
                <label class="block text-gray-700 font-bold md:text-right mb-1 md:mb-0 pr-4" for="elec_end">
                  Bill End Date
                </label>
                <input
                  class="appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-800 leading-tight focus:outline-none focus:bg-white focus:border-primary"
                  id="elec_end" type="date" @update="setValue" @change="setValue" :value="getValue('elec_end')" />
              </div>
              <div class="mb-4">
                <label class="block text-gray-700 font-bold md:text-right mb-1 md:mb-0 pr-4">
                  Current Bill Amount
                </label>
                <input
                  class="appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-800 leading-tight focus:outline-none focus:bg-white focus:border-primary"
                  id="elec_bill_amount" type="text" @update="setValue" @change="setValue"
                  :value="getValue('elec_bill_amount')" />
              </div>
              <div class="mb-4" v-for="field in fields" v-bind:key="field.name">
                <label class="block text-gray-700 font-bold md:text-right mb-1 md:mb-0 pr-4" :for="field.name">
                  {{ field.label }} (KWh)
                </label>
                <input
                  class="appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-800 leading-tight focus:outline-none focus:bg-white focus:border-primary"
                  :id="field.name" type="text" @update="setValue" @change="setValue" :value="getValue(field.name)" />
              </div>
              <div v-if="custType == 'residential'" class="mb-4">
                <label class="block text-gray-700 font-bold md:text-right mb-1 md:mb-0 pr-4">
                  Concessions <span class="text-xs">(optional)</span>
                </label>
                <multiselect v-model="selectedElecConcession" :options="cOptionsElec" :multiple="true" track-by="id"
                  label="name" placeholder="Select a concession"></multiselect>
              </div>
            </el-collapse-item>
            <!-- Gas tab -->
            <el-collapse-item v-if="showGas && hasGas" title="Gas" name="gas_tab">
              <div class="mb-4">
                <label class="block text-gray-700 font-bold md:text-right mb-1 md:mb-0 pr-4" for="gas_start">
                  Bill Start Date
                </label>
                <input
                  class="appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-800 leading-tight focus:outline-none focus:bg-white focus:border-primary"
                  id="gas_start" type="date" @update="setValue" @change="setValue" :value="getValue('gas_start')" />
              </div>
              <div class="mb-4">
                <label class="block text-gray-700 font-bold md:text-right mb-1 md:mb-0 pr-4" for="gas_end">
                  Bill End Date
                </label>
                <input
                  class="appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-800 leading-tight focus:outline-none focus:bg-white focus:border-primary"
                  id="gas_end" type="date" @update="setValue" @change="setValue" :value="getValue('gas_end')" />
              </div>
              <div class="mb-4">
                <label class="block text-gray-700 font-bold md:text-right mb-1 md:mb-0 pr-4">
                  Current Bill Amount
                </label>
                <input
                  class="appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-800 leading-tight focus:outline-none focus:bg-white focus:border-primary"
                  id="gas_bill_amount" type="text" @update="setValue" @change="setValue"
                  :value="getValue('gas_bill_amount')" />
              </div>
              <div class="mb-4">
                <label class="block text-gray-700 font-bold md:text-right mb-1 md:mb-0 pr-4" for="gas_usage">
                  Total gas usage (MJ)
                </label>
                <input
                  class="appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-800 leading-tight focus:outline-none focus:bg-white focus:border-primary"
                  id="gas_usage" type="text" @update="setValue" @change="setValue" :value="getValue('gas_usage')" />
              </div>
              <div v-if="custType == 'residential'" class="mb-4">
                <label class="block text-gray-700 font-bold md:text-right mb-1 md:mb-0 pr-4">
                  Concessions <span class="text-xs">(optional)</span>
                </label>
                <multiselect v-model="selectedGasConcession" :options="cOptionsGas" :multiple="false" track-by="id"
                  label="name" placeholder="Select a concession"></multiselect>
              </div>
            </el-collapse-item>
            <!-- Concessions tab -->
            <!-- <el-collapse-item title="Concessions" name="concessions_tab">
              <multiselect v-model="selectedConcession" :options="cOptions" :multiple="true" track-by="id" label="name" placeholder="Select a concession"></multiselect>
            </el-collapse-item> -->
          </el-collapse>

        </div>
      </div>


      <div class="flex justify-end space-x-4">
        <button :class="calculateBtnColor" class="uppercase text-white font-bold shadow-lg rounded py-1.5 px-4" @click="runCalc">
          {{ calculateBtnText }}
        </button>
        <button class="font-bold font-blue py-1.5 px-4 underline" @click="modalStore.resetCalc">
          Reset
        </button>
      </div>

    </div>
  </div>
</template>

<style>
.el-collapse .el-collapse-item__wrap {
  overflow: visible;
}
</style>