import { createRouter, createWebHistory } from "vue-router";
import SearchView from "../views/SearchView.vue";
import LandingSearchView from "../views/LandingSearchView.vue";
import PlanView from "../views/PlanView.vue";
import notListedAddress from "../views/notListedAddress.vue";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "search",
      component: SearchView,
    },
    {
      path: "/energy-assistance",
      name: "energy-assistance",
      component: LandingSearchView,
    },
    {
      // path: "/plans/:postcode/:ref/:addressidentifier/:state/:suburb/:address/",
      path: "/plans/:nmi/:checksum/:postcode/:addressidentifier/:state/:suburb/:address/",
      name: "plans",
      component: PlanView,
    },
    {
      path: "/not-listed",
      name: "notListedAddress",
      component: notListedAddress
    }
  ],
});

export default router;
