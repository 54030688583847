<script setup>

import { computed, ref } from "vue";
import { useRoute } from "vue-router";
import _ from "lodash";
import CryptoJS from 'crypto-js';

const props = defineProps({
  elec: Object,
  gas: Object,
  state: String,
  address: String,
  planKey: Number,
  planLength: Number,
  vstr: String,
});

import { storeToRefs } from "pinia";
import { useModalStore } from "@/stores/modals.js";

const data = useModalStore();
const {
  isCustomerUsageElec,
  isCustomerUsageGas,
  showUsagePopup,
  usage,
  estimate,
  defaultOffer,
  state_territory,
  formatted_address
} = storeToRefs(data);

const planDescriptionCss ={
  'max-height':"12vh",
  'overflow-y':'auto', 
}
const { showCallModal, startTime } = storeToRefs(useModalStore());

const eDiff = computed(() => {
  if (props.elec?.dmo_vdo_perc) {
    return Math.round(
      Math.abs(props.elec?.dmo_vdo_perc),
      0
    );
  }

  return null;
});

const marketOffer = computed(() => {

})

const base = import.meta.env.VITE_BASE_URL;
const comparable_base = import.meta.env.VITE_COMPARABLE_SITE_URL;
const my_v = ref(false);

const annualEstimate = computed(() => {
  const m = useModalStore();
  return m.estimate[props.elec.offerId];
});

const tariffType = computed(() => {
  const m = useModalStore();
  return m.tariff;
});

const elecUsage = computed(() => {
  if(!props.elec) return '';
  const m = useModalStore();

  if (my_v.value) {
    return `$${ Math.round(props.elec?.retailer_cost) } p/a`;
  }
  
  return `$${ Math.round(props.elec?.estimated_monthly_price) } monthly`;
});

const gasUsage = computed(() => {
  if(!props.gas) return '';
  const m = useModalStore();

  if (my_v.value) {
    return `$${ Math.round(props.gas?.estimated_annual_price) } p/a`;
  }
  
  return `$${ Math.round(props.gas?.estimated_monthly_price) } monthly`;
});

const route = useRoute();

const redirectToForm = () => {
  let url = '';

  let params = new URLSearchParams({
    elec : props.elec ? props.elec.offer_id : '',
    gas : props.gas ? props.gas.offer_id : '',
    address_identifier : props.address,
    tariff_type : props.elec?.tariff_type,
    state : props.state,
    type : data.custType,
    msat : route.params.addressidentifier,
    postcode : route.params.postcode,
    ntc_code : props.elec ? props.elec.ntc_code : '',
    // address_id : CryptoJS.AES.decrypt(route.params.ref, "N1788D6vrv9stpDcZjtN8").toString(CryptoJS.enc.Utf8),
    n : route.params.nmi,
    c : route.params.checksum,
    t : startTime.value,
    d : my_v.value ? 'a' : 'm', // display price in annual or monthly,
    mi : (typeof data.mirnInfo !== "undefined") ? CryptoJS.AES.encrypt(data.mirnInfo.mirn, "N1788D6vrv9stpDcZjtN8").toString() : '',
    mc : (typeof data.mirnInfo !== "undefined") ? data.mirnInfo?.checksum : '',
  });

  if (data.isAgent === true) {
    params.append('agt', 1);
  }

  if (data.leadId) {
    params.append('lead', data.leadId);
  }

  if (data.encryptedLead === true) {
    params.append('enc', 1);
  }

  if (data.contactId !== null) {
    params.append('cId', data.contactId);
  }

  if (data.addEnergyAccount === true) {
    params.append('add_EN', 1);
    params.append('agt', 1);
  }

  if (data.contactSignature !== null) {
    params.append('signature', data.contactSignature);
  }

  if (data.isCustomerUsageGas === true) {
    const gasQuoteResultId = estimate.value[props.gas?.offer_id]['id'].toString();

    params.append('g_cqr', CryptoJS.AES.encrypt(gasQuoteResultId, "N1788D6vrv9stpDcZjtN8").toString());
  }

  if (data.isCustomerUsageElec === true) {
    const elecQuoteResultId = estimate.value[props.elec?.offer_id]['id'].toString();

    params.append('e_cqr', CryptoJS.AES.encrypt(elecQuoteResultId, "N1788D6vrv9stpDcZjtN8").toString());
  }

  // Add postback parameter for Cohort Digital
  if (route.query.transaction_id && route.query.aff_ad) {
    params.append('coh_trans', route.query.transaction_id);
    params.append('aff_ad', route.query.aff_ad);
  }

  window.location.href = comparable_base + '/form?' + params.toString();
};

const getPlanEligibilityList = (eligilibility) => {

  return eligilibility.split(/\r?\n/);
}; 

const customBtnClass = computed(() => {
  if (props.elec?.retailer_cost == 0)
    return 'text-base font-medium underline cursor-pointer text-accent step4';

  return 'text-xs underline cursor-pointer step4';
});

const enableChooseBtn = computed(() => {
  let enableBtn = true;

  if (props.elec?.retailer_cost <= 0 && isCustomerUsageElec.value  === false) {
    enableBtn = false;
  }
    
  if (props.gas?.gas_annual_estimate <= 0 && isCustomerUsageGas.value  === false) {
    enableBtn = false;
  }

  // if (isCustomerUsageGas.value === true && estimate[props.elec?.offer_id]['estimated_bill'] > 0) {
  //   enableBtn = false;
  // }

  // if (isCustomerUsageGas.value === true && estimate[props.gas?.offer_id]['estimated_bill'] > 0) {
  //   enableBtn = false;
  // }

  return enableBtn;
});
</script>

<template>
    
  <div class="flex flex-col items-start">

    <!-- monthly/yearly switch section -->
    <div class="font-poppins flex justify-between items-center mt-2 mb-4">
      <p class="text-center text-xs p-1 ml-20">
        {{ props.planKey + 1 }} of {{ props.planLength }} results
      </p>
      <div class="usage-switch  text-right text-xs text-black step2 px-6">
        monthly
        <el-tooltip
          :content="'Switch value: monthly or yearly'"
          placement="top"
        >
          <el-switch
            :disabled="isCustomerUsageElec || isCustomerUsageGas"
            v-model="my_v"
            style="
              --el-switch-on-color: #E9EBF5;
              --el-switch-off-color: #F3F5F7;
            "
          />
        </el-tooltip>
          annual
      </div>
    </div>
    
    <!-- electricty plan card -->
    <div v-if="elec" class="relative rounded-xl filter border border-gray-100 bg-white mt-1 mb-4 w-full">
      <div class="plan-header w-full py-2 px-4 border border-gray-200 bg-blue rounded-t-xl">
        <div class="font-poppins text-md text-white text-center">
          <div v-if="elec?.retailer_cost == 0" class="text-sm">
            Annual estimates are not applicable for your tariff type. Please customise quote below.
          </div>
         <!-- <div v-else-if="elec?.dmo_vdo_perc == 0">
            Equal to the {{ state.toLowerCase() == "vic" ? "victorian default offer" : "default market offer" }}
          </div>-->
              <div v-else-if="elec?.dmo_vdo_perc == 0 || elec?.dmo_vdo_perc == null">
            Equal to the {{ state.toLowerCase() == "vic" ? "victorian default offer" : "default offer" }}
          </div>
          <div v-else>
            <span class="font-bold">{{ eDiff }}%</span> {{ elec.less_more }} than the
            {{ state.toLowerCase() == "vic" ? "victorian default offer" : "default market offer" }}
          </div>
        </div>
      </div>
      <div class="px-4 py-2">
      
        <div class="flex gap-4 justify-between mb-2">
          <div class="flex-none">
            <div class="flex flex-row gap-2 items-center">
              <img class="w-5" src="/icons/icon-electricity.svg" />
              <h1 class="font-poppins font-medium text-md">Electricity</h1>
            </div>
          </div>
          <div class="flex-none">
            <div class="flex flex-row">
              <button class="font-poppins text-xs px-2 py-1 more-info step5 text-left" @click.prevent="data.showPostDetails(elec)">
                <font-awesome-icon icon="fa-solid fa-circle-info" class="w-5" />
                <span class="underline">More info</span>
              </button>
              <button class="font-poppins text-xs px-2 py-1 more-info step5 text-left" @click.prevent="data.showEligibility(elec)">
                <font-awesome-icon icon="fa-solid fa-circle-question" class="w-5" />
                <span class="underline">Plan Eligibility</span>
              </button>
            </div>
          </div>
        </div>

        <div v-if="props.elec?.product?.special_offer" class="flex items-center justify-center mb-3">
          <p class="text-xs mb-0 bg-accent text-white py-1 px-3 text-center font-bold">
            Special Offer: {{ props.elec?.product?.special_offer }}*<sup>1</sup>
          </p>
        </div>

        <div class="font-poppins plan-info grid grid-cols-4 gap-x-2 pb-4">
          <div class="plan-logo">
            <img class="xl:h-12 xl:mr-2 xl:mt-4 align-left" :src="elec?.retailer?.logo" />
          </div>
          <div class="col-span-2 flex flex-col items-center">
            <div class="plan-name mb-2">
              <h2 class="font-bold text-lg lg:text-2xl">{{ elec?.product?.product_name }}</h2>
            </div>
            <div class="bill-usage text-center">
              <div v-if="isCustomerUsageElec">
                <div>
                  <span class="text-accent text-3xl font-bold leading-none">
                    <span>
                      ${{ parseFloat(estimate[elec.offer_id]['estimated_bill']).toFixed(2) }}
                      <span class="text-sm font-normal"> with given usage</span>
                    </span>
                  </span>
                </div>
              </div>
              <div v-else>
                <span class="text-accent text-3xl font-black leading-none step3">
                  <span v-if="my_v">
                    <template v-if="elec.retailer_cost && elec.retailer_cost > 0">
                      ${{ Math.round(elec.retailer_cost) }}
                      <span class="text-sm font-normal">p/a</span>
                    </template>
                  </span>
                  <span v-else>
                    <template v-if="elec.estimated_monthly_price && elec.estimated_monthly_price > 0">
                      ${{ Math.round(elec.estimated_monthly_price) }}
                      <span class="text-sm font-normal">/month</span>
                    </template>
                  </span>
                </span>
              </div>
              <span :class="customBtnClass" v-on:click="showUsagePopup = true">Customise Quote</span>
            </div>
          </div>
        </div>
      
        <!-- electricity clear advise-->
        <div v-if="!isCustomerUsageElec && ((elec.retailer_cost && elec.retailer_cost > 0) || (elec.estimated_monthly_price && elec.estimated_monthly_price > 0))" class="font-poppins plan-desc text-black p-4 bg-gray-100 border-gray-100 border-2 rounded-lg text-sm lg:text-base">
          <div v-html="elec?.product?.clear_advice" class="text-justify lg:text-left" :style="planDescriptionCss" ></div>
        </div>

        <!-- electricity special offer desc-->
        <div v-if="elec.product?.special_offer_desc" class="font-poppins text-black py-4">
          <p class="text-xs">
            <span>*<sup>1</sup>Special Offer Terms: </span>
            <span v-html="elec.product?.special_offer_desc"></span>
          </p>
        </div>

        <div class="text-right my-2">
          <a v-if="state == 'VIC'" :href="elec.epfs" target="_blank" class="text-right text-xs font-poppins underline">
            Energy Fact Sheet <font-awesome-icon icon="fa-solid fa-arrow-right" class="w-2" />
          </a>
          <a v-else :href="elec.product.energy_fact_sheet" target="_blank" class="text-right text-xs font-poppins underline">
            Energy Fact Sheet <font-awesome-icon icon="fa-solid fa-arrow-right" class="w-2" />
          </a>
        </div>
        
      </div>
      <div v-if="isCustomerUsageElec" class="text-sm pb-10 px-4 text-center lg:flex lg:flex-row justify-center">
        <div class="lg:w-1/3">
          <table class="w-full">
            <tbody>
              <tr>
                <td class="text-left text-xs font-bold">Your current bill: </td>
                <td class="text-right text-xs">${{ parseFloat(estimate[elec.offer_id]['old_bill'].replace(',', '')).toFixed(2) }}</td>
              </tr>
              <tr>
                <td class="text-left text-xs font-bold">Savings on this bill: </td>
                <td class="text-right text-xs">${{ parseFloat(estimate[elec.offer_id]['saving'].replace(',', '')).toFixed(2) }}</td>
              </tr>
              <tr>
                <td class="text-left text-xs font-bold">Annualised Saving: </td>
                <td class="text-right text-xs">${{ parseFloat(estimate[elec.offer_id]['annual_saving'].replace(',', '')).toFixed(2) }}</td>
              </tr>
              <tr>
                <td class="text-left text-xs font-bold">Saving Percentage: </td>
                <td class="text-right text-xs">{{ estimate[elec.offer_id]['annual_saving_perc'] }}%</td>
              </tr>
            </tbody>
          </table>
        </div>
        
      </div>
      <div v-else>
        <div v-if="elec.scriptBlock" class="text-xs p-6" v-html="elec.scriptBlock"></div>
      </div>
    </div>

    <!-- gas plan card -->
    <div v-if="gas" class="relative rounded-xl filter border border-gray-100 bg-white w-full">
      <div class="plan-header w-full py-2 px-4 border border-blue bg-blue rounded-t-xl">
        &nbsp;
      </div>
      <div class="px-4 py-2">  

        <div class="flex gap-4 justify-between mb-2">
          <div class="flex-none">
            <div class="flex flex-row gap-2 items-center">
              <img class="w-5" src="/icons/icon-gas.svg" />
              <h1 class="font-poppins font-medium text-md">Gas</h1>
            </div>
          </div>
          <div class="flex-none">
            <div class="flex flex-row">
              <button class="font-poppins text-xs px-2 py-1 more-info step5 text-left" @click.prevent="data.showPostDetails(gas)">
                <font-awesome-icon icon="fa-solid fa-circle-info" class="w-5" />
                <span class="underline">More info</span>
              </button>
              <button class="font-poppins text-xs px-2 py-1 more-info step5 text-left" @click.prevent="data.showEligibility(gas)">
                <font-awesome-icon icon="fa-solid fa-circle-question" class="w-5" />
                <span class="underline">Plan Eligibility</span>
              </button>
            </div>
          </div>
        </div>

        <div v-if="props.gas?.product?.special_offer" class="flex items-center justify-center mb-3">
          <p class="text-xs mb-0 bg-accent text-white py-1 px-3 text-center font-bold">
            Special Offer: {{ props.gas?.product?.special_offer }}*<sup>1</sup>
          </p>
        </div>

        <div class="font-poppins plan-info grid grid-cols-4 gap-x-2 pb-4">
          <div class="plan-logo">
            <img class="xl:h-12 xl:mt-4" :src="props.gas?.retailer?.logo" />
          </div>
          <div class="col-span-2 flex flex-col items-center">
            <div class="plan-name text-left mb-2">
              <h2 class="font-bold text-lg lg:text-2xl">{{ props.gas?.product?.product_name }}</h2>
            </div>
            <div class="bill-usage text-center">
              <div v-if="!isCustomerUsageGas">
                <span class="text-accent text-3xl font-black leading-none">
                  <span v-if="my_v">
                    ${{ Math.round(gas?.gas_annual_estimate) }}
                    <span class="text-sm font-normal">p/a</span>
                  </span>
                  <span v-else>
                    ${{ Math.round(gas?.gas_annual_estimate / 12) }}
                    <span class="text-sm font-normal">/month</span>
                  </span>
                </span>
              </div>
              <div v-else>
                <span class="text-accent text-3xl font-bold leading-none">
                  ${{ parseFloat(estimate[props.gas?.offer_id]['estimated_bill']).toFixed(2) }}
                </span>
                <span class="text-sm text-accent">
                  with usage given
                </span>
              </div>
              <span class="text-xs underline cursor-pointer" v-on:click="showUsagePopup = true">Customise Quote</span>
            </div>
          </div>
        </div>

        <!-- gas clear advise-->
        <div v-if="!isCustomerUsageGas" class="font-poppins plan-desc text-black p-4 bg-gray-100 border-gray-100 border-2 rounded-lg text-sm lg:text-base">
          <div v-html="gas?.product?.clear_advice" class="relative h-full text-justify lg:text-left"></div>
        </div>

        <!-- gas special offer desc-->
        <div v-if="gas.product?.special_offer_desc" class="font-poppins text-black py-4">
          <p class="text-xs">
            <span>*<sup>1</sup>Special Offer Terms: </span>
            <span v-html="gas.product?.special_offer_desc"></span>
          </p>
        </div>

        <div class="text-right my-2">
          <a v-if="state == 'VIC'" :href="gas.epfs" target="_blank" class="text-right text-xs font-poppins underline">
            Energy Fact Sheet <font-awesome-icon icon="fa-solid fa-arrow-right" class="w-2" />
          </a>
          <a v-else :href="gas.product.energy_fact_sheet" target="_blank" class="text-right text-xs font-poppins underline">
            Energy Fact Sheet <font-awesome-icon icon="fa-solid fa-arrow-right" class="w-2" />
          </a>
        </div>
         
      </div>
      <div v-if="isCustomerUsageGas" class="text-sm pb-10 px-4 text-center lg:flex lg:flex-row justify-center">
        <div class="lg:w-1/3">
          <table class="w-full">
            <tbody>
              <tr>
                <td class="text-left text-xs font-bold">Your current bill: </td>
                <td class="text-right text-xs">${{ parseFloat(estimate[props.gas?.offer_id]['old_bill'].replace(',', '')).toFixed(2) }}</td>
              </tr>
              <tr>
                <td class="text-left text-xs font-bold">Saving on this bill: </td>
                <td class="text-right text-xs">${{ parseFloat(estimate[props.gas?.offer_id]['saving'].replace(',', '')).toFixed(2) }}</td>
              </tr>
              <tr>
                <td class="text-left text-xs font-bold">Annualised Saving: </td>
                <td class="text-right text-xs">${{ parseFloat(estimate[props.gas?.offer_id]['annual_saving'].replace(',', '')).toFixed(2) }}</td>
              </tr>
              <tr>
                <td class="text-left text-xs font-bold">Saving Percentage: </td>
                <td class="text-right text-xs">{{ estimate[props.gas?.offer_id]['annual_saving_perc'] }}%</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      
    </div>

    <!-- speak to an expert section -->
    <div class="font-poppins h-10 w-full text-sm py-3 px-2 text-center"> 
      <!-- <tt>{{ vstr }}</tt>&nbsp; -->
      Have a question?&nbsp;
      <span class="py-2 cursor-pointer underline" @click.prevent="showCallModal = true">Speak to an expert</span> 
    </div>

  </div>

  <div v-if="enableChooseBtn" class="mx-4 lg:mx-auto max-w-sm step8">
    <button @click="redirectToForm" class="choose-plan-button block w-full uppercase bg-accent text-white font-quicksand font-bold shadow-lg rounded-full py-3 px-6 mt-2 hover:bg-blue transform">
      Choose This Plan <font-awesome-icon class="ml-2" icon="fa-solid fa-arrow-right" />
    </button>
  </div>
  <div v-else class="mx-4 lg:mx-auto max-w-sm step8">
    <button disabled="disabled" class="block w-full uppercase bg-gray-200 text-white font-quicksand font-bold shadow-lg rounded-full py-3 px-6 mt-2 hover:bg-gray transform cursor-not-allowed">
      Choose This Plan <font-awesome-icon class="ml-2" icon="fa-solid fa-arrow-right" />
    </button>
  </div>
</template>
<style>
.plan-desc p {
  margin-bottom: 0.5rem;
}
</style>
