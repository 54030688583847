import { defineStore } from "pinia";
import { distmap } from "@/assets/distmap";
import _ from "lodash";

const ComparableApiAccessToken = import.meta.env.VITE_COMPARABLE_API_ACCESS_TOKEN;
const pkgPath = import.meta.env.VITE_COMPARABLE_API_BASE_URL;
const websiteUrl = import.meta.env.VITE_COMPARABLE_SITE_URL;

const addrApi = "https://uqpjz32yxa.ap-southeast-2.awsapprunner.com/detail/";
const geoscapeApi = "https://api.psma.com.au/v1/predictive/address/";
const geoscapeApiKey = "IeTsnmO4QxvKzYhndFaIG8prW2DNoqN7";

const AlintaApiUrl = "https://alinta-sales-tool-api.azure-api.net/";
const AlintaApiSubsKey = "e8d3f7294f594e369ff894fde1162a9b";
const AlintaApiUserName = "0oa2l5vzzbNBzL4Wj3l7";
const AlintaApiPassword = "SzjdtNr9R5bfaIL8erWdCdCyUedh4batdnh0jX0G";

var ver = _.nth( _.split(_.get(import.meta.env, "VITE_VSTR", "--" + Date.now()), "-"), 2 );

export const useModalStore = defineStore({
    id: "modal",
    state: () => ({
        showInfoPopup: true,
        showCallModal: false,
        showPlanModal: false,
        showFilterModal: false,
        showServicePopup: false,
        isCustomerUsageElec: false,
        isCustomerUsageGas: false,
        showUsagePopup: false,
        plan: null,
        userElecBill: 0,
        userElecBillDays: -1,
        userGasBill: 0,
        userGasBillDays: -1,
        postcode: null,
        service: {},
        usage: {},
        concession: {},
        hasSolar: true,
        showElec: true,
        showGas: true,
        dist: null,
        retailer: null,
        isLoading: true,
        isCalculating: false,
        tariff: null,
        custType: "RESIDENTIAL",
        offers: [],
        offerListE: [],
        offerListG: [],
        plans: [],
        hasElec: false,
        hasGas: false,
        elecDistStr: null,
        gasDistStr: null,
        filter: [],
        defaultOffer: {},
        estimate: {},
        address: null,
        state: null,
        state_territory: null,
        formatted_address: null,
        ntc: null,
        tariffFeatures: [],
        leadId: null,
        planTier: {},
        startTime: Date.now(), // JS Timestamp,
        isAgent: false,
        alintaAccessToken: null,
        getAlintaNMI: false,
        encryptedLead: false,
        nmiInfo: null,
        contactId: null,
        addEnergyAccount: false,
        contactSignature: null,
        mirnInfo: null,
        showEligibilityModal: false,
        customizeQuoteId: null,
    }),
    getters: {},
    actions: {
        setAddress(data) {
            this.address = data;
        },
        setAddressState(data) {
            this.state = data;
        },
        setStateTerritory(data) {
            this.state_territory = data;
        },
        setFormattedAddress(data) {
            this.formatted_address = data;
        },
        showPostDetails(plan) {
            this.plan = plan;
            this.showPlanModal = true;

            // set the plan tier
            var peakTier1Start = (plan.peak_tier_1 > 0) ? 0.00: '0.00+';
            var peakTier1End = (plan.peak_tier_1 > 0) ? parseFloat(plan.peak_tier_1) : 0.00;

            var peakTier2Start = (plan.peak_tier_2 > 0) ? parseFloat(plan.peak_tier_1) + 0.01 : 0.00;
            var peakTier2End = (plan.peak_tier_2 > 0) ? peakTier2Start + parseFloat(plan.peak_tier_2) : 0.00;

            var peakTier3Start = (plan.peak_tier_3 > 0) ? peakTier2End + 0.01 : 0.00;
            var peakTier3End = (plan.peak_tier_3 > 0) ? peakTier3Start + parseFloat(plan.peak_tier_3) : 0.00;

            var peakTier4Start = (plan.peak_tier_4 > 0) ? peakTier3End + 0.01 : 0.00;
            var peakTier4End = (plan.peak_tier_4 > 0) ? peakTier4Start + parseFloat(plan.peak_tier_4) : 0.00;

            var peakTier5Start = (plan.peak_tier_5 > 0) ? peakTier4End + 0.01 : 0.00;
            var peakTier5End = (plan.peak_tier_5 > 0) ? peakTier3Start + parseFloat(plan.peak_tier_5) : 0.00;

            

            var offPeakTier1Start = (plan.off_peak_tier_1 > 0) ? 0.00: '0.00+';
            var offPeakTier1End = (plan.off_peak_tier_1 > 0) ? parseFloat(plan.off_peak_tier_1) : 0.00;

            var offPeakTier2Start = (plan.off_peak_tier_2 > 0) ? parseFloat(plan.off_peak_tier_1) + 0.01 : 0.00;
            var offPeakTier2End = (plan.off_peak_tier_2 > 0) ? offPeakTier2Start + parseFloat(plan.off_peak_tier_2) : 0.00;

            var offPeakTier3Start = (plan.off_peak_tier_3 > 0) ? offPeakTier2End + 0.01 : 0.00;
            var offPeakTier3End = (plan.off_peak_tier_3 > 0) ? offPeakTier3Start + parseFloat(plan.off_peak_tier_3) : 0.00;

            var offPeakTier4Start = (plan.off_peak_tier_4 > 0) ? offPeakTier3End + 0.01 : 0.00;
            var offPeakTier4End = (plan.off_peak_tier_4 > 0) ? offPeakTier4Start + parseFloat(plan.off_peak_tier_4) : 0.00;

            var offPeakTier5Start = (plan.off_peak_tier_5 > 0) ? offPeakTier4End + 0.01 : 0.00;
            var offPeakTier5End = (plan.off_peak_tier_5 > 0) ? offPeakTier3Start + parseFloat(plan.off_peak_tier_5) : 0.00;


            this.planTier = {
                'peakTier1Start': peakTier1Start,
                'peakTier1End' : peakTier1End,
                'peakTier2Start': peakTier2Start,
                'peakTier2End': peakTier2End,
                'peakTier3Start': peakTier3Start,
                'peakTier3End': peakTier3End,
                'peakTier4Start': peakTier4Start,
                'peakTier4End': peakTier4End,
                'peakTier5Start': peakTier5Start,
                'peakTier5End': peakTier5End,

                'offpeakTier1Start': offPeakTier1Start,
                'offpeakTier1End' : offPeakTier1End,
                'offpeakTier2Start': offPeakTier2Start,
                'offpeakTier2End': offPeakTier2End,
                'offpeakTier3Start': offPeakTier3Start,
                'offpeakTier3End': offPeakTier3End,
                'offpeakTier4Start': offPeakTier4Start,
                'offpeakTier4End': offPeakTier4End,
                'offpeakTier5Start': offPeakTier5Start,
                'offpeakTier5End': offPeakTier5End
            }
        },
        showEligibility(plan) {
            this.showEligibilityModal = true;
            this.plan = plan;
        },
        servicePopup(data) {
            this.service = data;
            this.dist = data;
            this.showServicePopup = true;
        },
        setRetailerInfo(data) {
            this.retailer = data;
        },
        showFuel(elec, gas) {
            // FIXME: don't know why this is required; should just be able to call setPairs...
            this.showElec = elec;
            this.showGas = gas;
            this.setPairs(this.showElec, this.showGas);
        },
        async setUserUsage() {
            this.isCustomerUsage = true;
            this.isCalculating = true;

            // save user input to database
            const userInputs = this.usage;
            Object.assign(userInputs, {
                address: this.formatted_address,
                address_type: this.custType
            });

            var requestOptionsCustomQuote = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + ComparableApiAccessToken,
                    'Content-Type' : 'application/json',
                },
                body: JSON.stringify(userInputs),
                redirect: 'follow'
            }

            var saveQuoteInputs = await fetch(pkgPath + "customize-quote", requestOptionsCustomQuote);
            if (saveQuoteInputs.status == 200) {
                var quoteResponse = await saveQuoteInputs.json();

                this.customizeQuoteId = quoteResponse.quote_id;
            }

            if (this.usage?.elec_start != null && this.usage?.elec_end != null) {
                for (const offerId of this.offerListE) {
                    for(const plan of this.plans) {
                    
                        if ( offerId == _.get(plan, 'elec.offer_id') ) {

                            const bodyContent = this.usage;

                            Object.assign(bodyContent, {
                                offer_id: offerId,
                                ntc_code: this.ntc,
                                quote_id: this.customizeQuoteId
                            });

                            var requestOptions = {
                                method: 'POST',
                                headers: {
                                    'Accept': 'application/json',
                                    'Authorization': 'Bearer ' + ComparableApiAccessToken,
                                    'Content-Type' : 'application/json',
                                },
                                body: JSON.stringify(bodyContent),
                                redirect: 'follow'
                            }
                    
                            // make a request for getting the distributor data
                            var elecQuoteUrl = "customize-quote/elec";
                            var resElecQuote = await fetch(pkgPath + elecQuoteUrl, requestOptions);
                            if (resElecQuote.status == 200) {
                                var gt = await resElecQuote.json();

                                this.estimate[offerId] = gt;
                            }
                        }

                    }
                }
                this.isCustomerUsageElec = true;
            }

            if (this.usage?.gas_start != null && this.usage?.gas_end != null) {

                for (const offerId of this.offerListG) {
                    for(const plan of this.plans) {
                    
                        if ( offerId == _.get(plan, 'gas.offer_id') ) {
                            const paymentOptions = [];

                            const gasBodyContent = this.usage;

                            Object.assign(gasBodyContent, {
                                offer_id: offerId,
                                ntc_code: this.ntc,
                                quote_id: this.customizeQuoteId
                            });

                            var gasRequestOptions = {
                                method: 'POST',
                                headers: {
                                    'Accept': 'application/json',
                                    'Authorization': 'Bearer ' + ComparableApiAccessToken,
                                    'Content-Type' : 'application/json',
                                },
                                body: JSON.stringify(gasBodyContent),
                                redirect: 'follow'
                            }

                            // make a request for getting the distributor data
                            var gasQuoteUrl = "customize-quote/gas";
                            var resGasQuote = await fetch(pkgPath + gasQuoteUrl, gasRequestOptions);
                            if (resGasQuote.status == 200) {
                                var gt = await resGasQuote.json();

                                this.estimate[offerId] = gt;
                            }

                        }

                    }
                }

                this.isCustomerUsageGas = true;
            }

            this.setPairs(this.showElec, this.showGas);
            this.showUsagePopup = false;
            this.isCalculating = false;
        },
        resetCalc() {
            this.isCustomerUsage = false;
            this.isCustomerUsageElec = false;
            this.isCustomerUsageGas = false;
            this.usage = {};
                
            for (const offerId of this.offerListE) {
                this.estimate[offerId] = this.defaultOffer[offerId]?.anualEstimate;
            }

            for (const offerId of this.offerListG) {
                let est = _.get(
                this.offers,
                [offerId, "defaultEstimate", "gas"],
                false
                );
                if (est) {
                this.estimate[offerId] = _.round((est * 1.1) / 100);
                } else {
                this.estimate[offerId] = null;
                }
            }

            this.setPairs(this.showElec, this.showGas);
            this.showUsagePopup = false;
        },
        async getAddress(nmi, checksum, postcode, address, suburb) {
            // make a request on the MSAT to get the tariff code
            let endpoint = "meter/electricity/detail/" + nmi + '?checksum=' + checksum;
            
            let resp = await fetch(AlintaApiUrl + endpoint, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Ocp-Apim-Subscription-Key': AlintaApiSubsKey,
                    'Authorization': "Bearer " + this.alintaAccessToken
                }
            });

            if (resp.ok === false) {
                this.isLoading = false;
                this.hasElec = false;
                this.hasGas = false;

                return;
            }

            var data = await resp.json();
            this.postcode = postcode;
            this.dist = data;

            this.elecDistStr = distmap[data.distributorCodes[0]];
            this.gasDistStr = distmap[data.distributorCodes[0]];
            // this.gasDistStr = distmap[data.gasInfo.zone];

            // format the ntc code
            // this.ntc = _.sortedUniq(data.tariffCode.sort()).join("+");
            this.ntc = data.tariffCode;

            var headers = {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + ComparableApiAccessToken
            }

            // make a request for getting the distributor data
            if ( (this.leadId && this.isAgent) || this.contactId) {
                var distriUrl = "distributor/" + this.ntc + "/" + postcode + "/" + suburb + '?o=sales';
            } else {
                var distriUrl = "distributor/" + this.ntc + "/" + postcode + "/" + suburb;
            }
            var res = await fetch(pkgPath + distriUrl, { headers });

            // check status
            if (res.status == 200) {
                var distriMap = await res.json();

                // get customer type
                this.custType = Object.keys(distriMap)[0];

                // get tariff type from elec
                this.tariff = _.get(distriMap, [this.custType, "elec", "tariff_type"], "<Unknown Tariff>");

                // check if the response has details for residential/business
                if ( this.custType == 'residential' || this.custType == 'business') {

                // check if has details for elec
                if ( _.has(distriMap, [this.custType, "elec"]) ) {
                    data.tariffStr = distriMap[this.custType]?.["elec"]?.tariff_type;
                    const tariffFeatures = [];

                    this.tariffFeatures = distriMap[this.custType]?.["elec"]?.tariffFeatures;

                    // get tariff features
                    if (this.tariffFeatures) {
                    if (this.tariffFeatures.general_usage == 1) {
                        tariffFeatures.push("GENERAL_USAGE");
                    } 

                    if (this.tariffFeatures.peak_usage == 1) {
                        tariffFeatures.push("PEAK_USAGE");
                    }

                    if (this.tariffFeatures.off_peak_usage == 1) {
                        tariffFeatures.push("OFF_PEAK_USAGE");
                    }

                    if (this.tariffFeatures.tou_shoulder == 1) {
                        tariffFeatures.push("TOU_SHOULDER");
                    }

                    if (this.tariffFeatures.controlled_load == 1) {
                        tariffFeatures.push('CONTROLLED_LOAD')
                    }

                    if (this.tariffFeatures.controlled_load_1 == 1) {
                        tariffFeatures.push('CONTROLLED_LOAD_1')
                    }

                    if (this.tariffFeatures.controlled_load_2 == 1) {
                        tariffFeatures.push('CONTROLLED_LOAD_2')
                    }

                    if (this.tariffFeatures.summer_demand == 1) {
                        tariffFeatures.push('SUMMER_DEMAND')
                    }

                    if (this.tariffFeatures.non_summer_demand == 1) {
                        tariffFeatures.push('NON_SUMMER_DEMAND')
                    }

                    if (this.tariffFeatures.climate_saver == 1) {
                        tariffFeatures.push('CLIMATE_SAVER')
                    }

                    if (this.tariffFeatures.solar == 1) {
                        tariffFeatures.push("SOLAR");
                    }
                    }
                    
                    this.usageInput = tariffFeatures;
                    this.offerListE = distriMap[this.custType]?.['elec']?.offer_ids ?? [];
                    // this.offerListE = distriMap[elec]?.['residential']?.RESIDENTIAL ?? [];
                    // this.usageInput = distriMap[elec]?.['residential']?._tariffFeature;
                    // this.defaultOffer = distriMap[elec]?.['residential']?._defaultOffer ?? {};
                    this.dist = data;
                    this.hasElec = true;
                } else {
                    this.hasElec = false;
                    this.showElec = false;
                }

                // check if has details for gas
                if ( _.has(distriMap, [this.custType, "gas"]) ) {
                    // var gasMap = await resG.json();
                    this.hasGas = true;
                    this.offerListG = distriMap[this.custType]?.['gas']?.offer_ids ?? [];

                    this.dist = data;
                    // this.offerListG = gasMap[Object.keys(gasMap)[0]]?.RESIDENTIAL ?? [];

                } else {
                    this.hasGas = false;
                    this.showGas = false;
                }

                // get the plans
                if ( (this.leadId && this.isAgent) || this.contactId) {
                    var plansUrl = "address-plans/" + this.ntc + "/" + this.postcode + "/" + suburb + '?o=sales';
                } else {
                    var plansUrl = "address-plans/" + this.ntc + "/" + this.postcode + "/" + suburb;
                }
                
                var planResponse = await fetch(pkgPath + plansUrl, { headers });

                if (planResponse.status == 200) {
            
                    var planRes = await planResponse.json();
                    
                    // assign the original response to  offers
                    this.offers = planRes[this.custType];
                    const gasPlans = Array.isArray(this.offers.gas) ? this.offers.gas : Object.values(this.offers.gas);
                    const elecPlans = Array.isArray(this.offers.elec) ? this.offers.elec : Object.values(this.offers.elec);
                    if (gasPlans.length) {
                    for (const gasPlan of gasPlans) {
                        for (const elecPlan of elecPlans) {
                        this.plans.push({
                            'gas': gasPlan || null,
                            'elec': elecPlan || null
                        });
                        }
                    }
                    } else {
                    for (const elecPlan of this.offers.elec) {
                        this.plans.push({
                        'elec': elecPlan || null
                        });
                    }
                    }
                }
                
                // redirect to contact page if no data for residential
                } else {
                    this.hasElec = false;
                    this.hasGas = false;
                    // window.location.href = "https://comparable.com.au/contact-us/?address=" + address;
                }

            } else {
                this.hasElec = false;
                this.hasGas = false;
                window.location.href = websiteUrl+"contact-us/?address=" + address;
            }

            return data;
        },
        setPairs(hasE, hasG) {
            var p = Array();
        
            if (hasE && hasG) {
                if (this.offers.gas && (Array.isArray(this.offers.gas) || typeof this.offers.gas === 'object')) {
                const gasPlans = Array.isArray(this.offers.gas) ? this.offers.gas : Object.values(this.offers.gas);
                const elecPlans = Array.isArray(this.offers.elec) ? this.offers.elec : Object.values(this.offers.elec);

                for (const gasPlan of gasPlans) {
                    for (const elecPlan of elecPlans) {
                    p.push({
                        'gas': gasPlan || null,
                        'elec': elecPlan || null
                    });
                    }
                }
                } else {
                for (const elecPlan of this.offers.elec) {
                    p.push({
                    'elec': elecPlan || null
                    });
                }
                }
                // for (const offerIdE of this.offerListE) {
                //   if (_.has(this.offers, offerIdE)) {
                //     for (const offerIdG of this.offerListG) {
                //       if (_.has(this.offers, offerIdG)) {
                //         p.push({
                //           e: this.offers[offerIdE],
                //           g: this.offers[offerIdG],
                //         });
                //       }
                //     }
                //   }
                // }
            } else if (hasE && !hasG) {
                for(const elecPLan of this.offers.elec) {
                p.push({
                    'elec': elecPLan
                });
                }
                // for (const offerIdE of this.offerListE) {
                //   if (_.has(this.offers, offerIdE)) {
                //     p.push({ e: this.offers[offerIdE], g: null });
                //   }
                // }
            } else if (hasG) {
                for(const gasPlan of this.offers.gas) {
                p.push({
                    'gas': gasPlan
                });
                }
            }

            if (this.filter.length > 0) {
                let f = this.filter;
                _.remove(p, function (d) {
                for (const fn of f) {
                    if (!fn(d)) {
                    return true;
                    }
                }
                return false;
                });
            }

            this.plans = _.sortBy(p, [
                (o) => {
                return (
                    (o.e != null
                    ? _.get(this.estimate, [o.e?.offerId], Number.MAX_SAFE_INTEGER)
                    : 0) +
                    (o.g != null
                    ? _.get(this.estimate, [o.g?.offerId], Number.MAX_SAFE_INTEGER)
                    : 0)
                );
                },
            ]);

            this.isLoading = false;
        },
        setLeadId(data) {
            this.leadId = data;
        },
        resetOffers() {
            this.offerListE = []
            this.offerListG = []
            this.plans = []
            this.offers = []
            this.hasElec = false
            this.hasGas = false
            this.postcode = null
            this.dist = null
            this.elecDistStr = null
            this.gasDistStr = null
            this.ntc = null
            this.tariffFeatures = []
        },
        setAgent(data) {
            this.isAgent = data;
        },
        async setAlintaAccessToken() {

            let endpoint = "ExternalSalesToken/token?grant_type=client_credentials&scope=v1/meter v1/products v1/sales";
            
            let res = await fetch(AlintaApiUrl + endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Ocp-Apim-Subscription-Key': AlintaApiSubsKey,
                    'Authorization': 'Basic ' + btoa( AlintaApiUserName + ':' + AlintaApiPassword )
                }
            });
    
            if (res.status == 200) {
                let result = await res.json();

                if (result.accessToken) {
                    this.alintaAccessToken = result.accessToken;
                }

                return result;
            }

            return false;
        },
        async getNMI(addressParams, accessToken) {
            let endpoint = "meter/electricity/search?matchingType=includeBestMatches";
            
            let res = await fetch(AlintaApiUrl + endpoint, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Ocp-Apim-Subscription-Key': AlintaApiSubsKey,
                    'Authorization': 'Bearer ' + accessToken
                },
                body: JSON.stringify(addressParams)
            });
    
            if (res.status == 200) {
                let result = await res.json();

                return result;
            }

            return false;
        },
        async getMsatAddress(id) {
            let headers = {
                'Content-Type': 'application/json',
                'Authorization': geoscapeApiKey
            }

            let res = await fetch(geoscapeApi + id, { headers });

            if (res.status == 200) {
                let geoscapeAddress = await res.json();
                
                if (geoscapeAddress.address) {
                    let _geoAddr = geoscapeAddress.address.properties;

                    return _geoAddr;

                    // get available electric meter
                    // let _AddrSearchParams = {
                    //     'suburb': _geoAddr.locality_name,
                    //     'postcode': _geoAddr.postcode,
                    //     'state': _geoAddr.state_territory,
                    //     'unitNumber': _geoAddr.complex_unit_identifier,
                    //     'unitType': _geoAddr.complex_unit_type,
                    //     'streetName': _geoAddr.street_name,
                    //     'streetType': _geoAddr.street_type,
                    // }

                    // get Alinta API Access Token
                    // this.setAlintaAccessToken().then((token) => {

                    //     // // get NMI
                    //     // let nmiResult = this.getNMI(_AddrSearchParams, token.accessToken).then((response) => {
                    //     //     if (response.bestMatches) {
                    //     //         return response.bestMatches;
                    //     //     }
                    //     // });

                    //     this.alintaAccessToken = token.accessToken;

                    // });

                    // console.log(this.alintaAccessToken);

                    // return nmiResult;
                }
            }

            return false;
        },
        async getNMIDetails(_nmi, _checksum) {
            // make a request on the MSAT to get the tariff code
            let endpoint = "meter/electricity/detail/" + _nmi + '?checksum=' + _checksum;
                        
            let resp = await fetch(AlintaApiUrl + endpoint, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Ocp-Apim-Subscription-Key': AlintaApiSubsKey,
                    'Authorization': "Bearer " + this.alintaAccessToken
                }
            });

            if (resp.ok === false) return false;

            return await resp.json();;
        },
        setNMIDetails(value) {
            this.nmiInfo = value;
        },
        setContactId(value) {
            this.contactId = value;
        },
        setAddEnergyAccount(value) {
            this.addEnergyAccount = value;
        },
        setContactSignature(value) {
            this.contactSignature = value;
        },
        async getMIRN(addressParams, accessToken) {
            let endpoint = "meter/gas/search";
            
            let res = await fetch(AlintaApiUrl + endpoint, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Ocp-Apim-Subscription-Key': AlintaApiSubsKey,
                    'Authorization': 'Bearer ' + accessToken
                },
                body: JSON.stringify(addressParams)
            });
    
            if (res.status == 200) {
                let result = await res.json();

                return result;
            }

            return false;
        },
        setMirnInfo(value) {
            this.mirnInfo = value; 
        }
    },
});
