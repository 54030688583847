<script setup>
import AddressInput from "@/components/AddressInput.vue";
import router from "@/router";

import { watch, ref, onMounted } from "vue";
import { Search, Message } from "@element-plus/icons-vue";
import { storeToRefs } from "pinia";
import { useComparatorStore } from "@/stores/comparator.js";
import { useModalStore } from "@/stores/modals";
import { useRoute } from 'vue-router';

import CryptoJS from 'crypto-js';

// Access environment variable
const websiteUrl = import.meta.env.VITE_COMPARABLE_SITE_URL;

var isError = ref(false);
var isExpand = ref(false);
var isLoading = ref(false);
var showManual = ref(false);
var showContact = ref(false);
var addressOption = ref('nmi');
var nmiInput = ref();

var addressList = ref([]);
const route = useRoute();
const modalStore = useModalStore();
const { address } = storeToRefs(useComparatorStore());
const { showInfoPopup, alintaAccessToken, getAlintaNMI } = storeToRefs(useModalStore());
var contact = ref({});

var cs_message = "Please enter your details below so one of our Energy experts can call you and offer you a better deal over the phone.";
var cs_message_empty = "Apologies. It seems your address isn't coming up with any matches in the Australia Energy database.";
var cs_message_manual = "Still can't find your address?";

function splitAddress(str) {
    const seg = str.split(", ");

    return [seg.slice(0, -3).join(", "), seg.slice(-3).join(", ")];
}

function onError() {
    isError.value = true;
    isLoading.value = false;
    isExpand.value = false;
    showContact.value = true;
    document.activeElement.blur();
    window.focus();
}

/* set the manual address fields from geoscape */
function setManual(addr) {
    address.value = addr;
}

/* List addresses returned back from MSATS */
function listAddress(list) {
    document.activeElement.blur();
    isExpand.value = true;
    isLoading.value = false;

    if (list.length > 0) {
        addressList.value = list;
    } else {
        isExpand.value = false;
        showManual.value = true;
    }

    // if (showManual.value) {
    //     showContact.value = true;
    // }

    window.focus();
}

/* Select an address from geoscape */
function getAddress(addr) {
    
    showInfoPopup.value = false;
    isLoading.value = true;
    
    // search directly to Alinta Meter API
    if (typeof addr.nmi !== "undefined" && addr.nmi === true) {
        let nmiUtilityAddress =  modalStore.nmiInfo?.utilityAddress;

        let nmiStreetNumber1 = null;
        if (nmiUtilityAddress.houseNumberSuffix) {
            nmiStreetNumber1 = nmiUtilityAddress.houseNumber + nmiUtilityAddress.houseNumberSuffix;
        } else if (nmiUtilityAddress.houseNumber) {
            nmiStreetNumber1 = nmiUtilityAddress.houseNumber;
        }

        let nmiStreetNumber2 = null;
        if (nmiUtilityAddress.houseNumberToSuffix) {
            nmiStreetNumber2 = nmiUtilityAddress.houseNumberTo + nmiUtilityAddress.houseNumberToSuffix;
        } else if (nmiUtilityAddress.houseNumberTo) {
            nmiStreetNumber1 = nmiUtilityAddress.houseNumberTo;
        }

        address.value = {
            "complex_level_number": (nmiUtilityAddress.floorOrLevelNumber) ? nmiUtilityAddress.floorOrLevelNumber : null,
            "complex_level_type": (nmiUtilityAddress.floorOrLevelType) ? nmiUtilityAddress.floorOrLevelType : null,
            "complex_level_type_description": null,
            "complex_unit_identifier": (nmiUtilityAddress.flatOrUnitNumber) ? nmiUtilityAddress.flatOrUnitNumber : null,
            "complex_unit_type": (nmiUtilityAddress.flatOrUnitType) ? nmiUtilityAddress.flatOrUnitType : null,
            "formatted_address": addr.value,
            "locality_name": (nmiUtilityAddress.suburbOrPlaceOrLocality) ? nmiUtilityAddress.suburbOrPlaceOrLocality : null,
            "location_descriptor": (nmiUtilityAddress.locationDesc) ? nmiUtilityAddress.locationDesc : null,
            "lot_identifier": (nmiUtilityAddress.lotNumber) ? nmiUtilityAddress.lotNumber : null,
            "postal_delivery_number": (nmiUtilityAddress.deliveryPointIdentifier) ? nmiUtilityAddress.deliveryPointIdentifier : null,
            "postcode": (nmiUtilityAddress.postcode) ? nmiUtilityAddress.postcode : null,
            "state_territory": (nmiUtilityAddress.stateOrTerritory) ? nmiUtilityAddress.stateOrTerritory : null,
            "street_name": (nmiUtilityAddress.streetName) ? nmiUtilityAddress.streetName : null,
            "street_number_1": nmiStreetNumber1,
            "street_number_2": nmiStreetNumber2,
            "street_suffix": (nmiUtilityAddress.streetsSuffix) ? nmiUtilityAddress.streetsSuffix : null,
            "street_type": (nmiUtilityAddress.streetType) ? nmiUtilityAddress.streetType : null,
        };

        modalStore.setAlintaAccessToken().then(() => {
            listAddress([modalStore.nmiInfo]);
        });
    } else {
        address.value = addr.value;
        modalStore.getMsatAddress(addr.id).then((address) => {
            isExpand.value = false;
            isLoading.value = true;
            setManual(address);
            
            // display Alinta Info stored
            if (modalStore.nmiInfo) {
                listAddress([modalStore.nmiInfo]);

            // search for address in Alinta API to get list of NMI
            } else {
                modalStore.setAlintaAccessToken().then(() => {
                    getAlintaNMI.value = true;
                });
            }
        });   
    }
}

/** get the NMI for the selected address */
watch(getAlintaNMI, (val) => {
    
    if (val === true) {
        let _AddrSearchParams = {
            'addressType': 'streetAddress',
            'postcode': address.value.postcode,
            'siteIdentifier': null,
            'state': address.value.state_territory,
            'streetName': address.value.street_name,
            'streetNumber': address.value.street_number_1.replace(/[^0-9]/g, ''),
            'streetNumberSuffix': address.value.street_number_1.replace(/\d+/g, ''),
            'streetNumberTo': null,
            'streetNumberToSuffix': null,
            'streetSuffix': null,
            'streetType': address.value.street_type_description,
            'suburb': address.value.locality_name,
        }

        if (address.value.complex_unit_identifier !== null) {
            Object.assign(_AddrSearchParams, {
                'unitNumber': address.value.complex_unit_identifier,
                'unitType': address.value.complex_unit_type_description
            });
        }

        modalStore.getNMI(_AddrSearchParams, alintaAccessToken.value).then((response) => {

            if (response.nmiMatch) {
                listAddress([response.nmiMatch]);
            } else if (response.bestMatches) {
                listAddress(response.bestMatches);
            } else {
                showContact.value = true;                
            }

            getAlintaNMI.value = false;
        })
    }

})

/* Search a manul address in msats */
function getManualAddress(addr) {
    isLoading.value = true;
    // comparable.address(JSON.stringify(addr), listAddress, onError);

    getAlintaNMI.value = true;
    isExpand.value = false;
}

function manualAddress() {
    isExpand.value = false;
    showManual.value = true;
}

function openAddress(item) {
    let formatted_add =  '';

    if (item.utilityAddress.flatOrUnitNumber)
        formatted_add += item.utilityAddress.flatOrUnitType + ' ' + item.utilityAddress.flatOrUnitNumber +', ';

    if (item.utilityAddress.floorOrLevelNumber)
        formatted_add += 'FLOOR ' + item.utilityAddress.floorOrLevelNumber + ', ';

    if (item.utilityAddress.lotNumber)
        formatted_add += 'LOT ' + item.utilityAddress.lotNumber + ', ';

    if (item.utilityAddress.houseNumberSuffix) {
        formatted_add += item.utilityAddress.houseNumber + item.utilityAddress.houseNumberSuffix + ' ';
    } else {
        formatted_add += item.utilityAddress.houseNumber + ' ';
    }

    if (item.utilityAddress.addressLine1)
        formatted_add += item.utilityAddress.addressLine1 + ', ';

    if (item.utilityAddress.addressLine2)
        formatted_add += item.utilityAddress.addressLine2 + ', ';

    formatted_add += item.utilityAddress.streetName + ' ' + item.utilityAddress.streetType + ' ' +
        item.utilityAddress.suburbOrPlaceOrLocality + ', ' + 
        item.utilityAddress.stateOrTerritory + ', ' + item.utilityAddress.postcode;

    modalStore.setAddressState(item.utilityAddress.stateOrTerritory);
    modalStore.setStateTerritory(item.utilityAddress.stateOrTerritory);
    modalStore.setFormattedAddress(formatted_add);

    router.push({
        name: "plans",
        params: { 
            postcode: item.utilityAddress.postcode, 
            nmi: CryptoJS.AES.encrypt(item.nmi, "N1788D6vrv9stpDcZjtN8").toString(),
            checksum: item.checksum,
            addressidentifier: window.btoa(address.value.address_identifier),
            state: item.utilityAddress.stateOrTerritory,
            address: formatted_add,
            suburb: item.utilityAddress.suburbOrPlaceOrLocality,
        },
        query: router.currentRoute.value.query
    });
}

watch(showContact, redirectToContact);

function redirectToContact() {
    if (showContact.value) {
        let url = `${websiteUrl}/contact-us/?address=not-listed`;
        window.location.href = url;
    }
}

function getNmiAddress(nmiInput) {
    showInfoPopup.value = false;
    isLoading.value = true;

    // request alinta access token
    modalStore.setAlintaAccessToken().then(() => {
        let _nmi = nmiInput.slice(0, 10);
        let _checksum = nmiInput.slice(-1);

        // get NMI details
        modalStore.getNMIDetails(_nmi, _checksum).then((resp) => {

            if (resp !== false) {
                // add NMI and NMI checksum to response
                Object.assign(resp, { nmi: _nmi, checksum: _checksum });
                modalStore.setNMIDetails(resp);

                listAddress([resp]);
            }

        });

    });
}

onMounted(() => {
    let params = {};

    if (route.query.stno)
        params.street_number_1 = route.query.stno;

    if (route.query.stname)
        params.street_name = route.query.stname;

    if (route.query.sttype)
        params.street_type = route.query.sttype;

    if (route.query.suburb)
        params.locality_name = route.query.suburb;

    if (route.query.state)
        params.state_territory = route.query.state;

    if (route.query.postcode)
        params.postcode = route.query.postcode;

    // make a query on Alinta API if params is not empty
    if ( Object.keys(params).length > 0 ) {
        address.value = params;

        modalStore.setAlintaAccessToken().then(() => {

            getAlintaNMI.value = true;
        });
    }

})
</script>

<template>
    <div class="py-4 px-4">
        <template v-if="!isError">
            <div class="flex justify-end">
                <div @click="manualAddress" v-show="!showManual" class="not-listed text-gray-5 hover:text-gray-8 text-sm mb-md cursor-pointer underline flex flex-row gap-2 items-center">
                    <div>
                        <img src="/icons/zondicons_question.png" />
                    </div>
                    My address is not listed
                </div>
            </div>
            <AddressInput v-if="!showManual" @select="getAddress" />

            <el-form v-else :model="address" label-width="120px" label-position="top">
                <!-- <div v-if="!isExpand">
                    <div class="mb-6">
                        <p>Looks like we couldn't find an exact match for your address, let's try a different approach.</p>
                    </div>
                </div> -->
                <el-row :gutter="4">
                    <el-col :span="24">
                        <el-radio-group v-model="addressOption">
                            <el-radio label="nmi">I have NMI number</el-radio>
                            <el-radio label="address">I have property address</el-radio>
                        </el-radio-group>
                    </el-col>
                </el-row>
                
                <div v-if="addressOption == 'address'">
                    <el-row :gutter="4">
                        <el-col :span="20" :xs="15">
                            <el-form-item label="Property Name">
                                <el-input v-model="address.site_name" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="4" :xs="9">
                            <el-form-item label="Lot Identifier">
                                <el-input v-model="address.lot_identifier" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="4">
                        <el-col :span="4" :xs="10">
                            <el-form-item label="Level Number">
                                <el-input v-model="address.complex_level_number" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8" :xs="14">
                            <el-form-item label="Level Type">
                                <el-input v-model="address.complex_level_type" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="4" :xs="10">
                            <el-form-item label="Unit Identifier">
                            <el-input v-model="address.unit_identifier" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8" :xs="14">
                            <el-form-item label="Unit Type">
                            <el-input v-model="address.flatOrUnitType" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="4">
                        <el-col :span="4" :xs="10">
                            <el-form-item label="Street Number">
                                <el-row :gutter="4">
                                    <el-col :span="11">
                                        <el-input v-model="address.street_number_1"/>
                                    </el-col>
                                    <el-col :span="2">
                                        <span class="text-gray-500">-</span>
                                    </el-col>
                                    <el-col :span="11">
                                        <el-input v-model="address.street_number_2"/>
                                    </el-col>
                                </el-row>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12" :xs="14">
                            <el-form-item label="Street Name">
                                <el-input v-model="address.street_name" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="4" :xs="16">
                            <el-form-item label="Street Type">
                                <el-input v-model="address.street_type" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="4" :xs="8">
                            <el-form-item label="Street Suffix">
                                <el-input v-model="address.street_suffix" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="4">
                        <el-col :span="16" :xs="24">
                            <el-form-item label="Suburb">
                                <el-input v-model="address.locality_name" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="4" :xs="16">
                            <el-form-item label="State">
                                <el-input v-model="address.state_territory" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="4" :xs="8">
                            <el-form-item label="Postcode">
                                <el-input v-model="address.postcode" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="4" justify="end">
                        <el-col :span="4" :xs="8">
                            <el-form-item>
                                <el-button
                                    style="width: 100%"
                                    type="primary"
                                    :icon="Search"
                                    :disable="isLoading"
                                    @click="getManualAddress(address)"
                                    >Search</el-button
                                >
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>

                <div v-if="addressOption == 'nmi'">
                    <el-row :gutter="4">
                        <el-col :span="24">
                            <el-form-item>
                                <el-input v-model="nmiInput" placeholder="Input your NMI (National Meter Identifier) here" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="4" justify="end">
                        <el-col :span="4" :xs="8">
                            <el-form-item>
                                <el-button
                                    style="width: 100%"
                                    type="primary"
                                    :icon="Search"
                                    :disable="isLoading"
                                    @click="getNmiAddress(nmiInput)"
                                    >Search</el-button
                                >
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>
            </el-form>

            <el-row :gutter="8">
                <el-col :span="24">
                    <el-progress
                        v-show="isLoading"
                        :percentage="10"
                        :indeterminate="true"
                        :text-inside="true"
                    />
                </el-col>
            </el-row>

            <template v-if="isExpand">
                
                <div class="flex flex-col lg:flex-row gap-4 justify-between lg:items-center mt-lg">
                    <div>
                        <h2 class="">Select your address</h2>
                    </div>
                </div>
                
                <el-table
                    class="search-result mb-xl"
                    :data="addressList"
                    @row-click="openAddress"
                    :show-header="false"
                    style="width: 100%"
                    max-height="50vh"
                >
                    <el-table-column prop="address" label="Address" max-width="500">
                        <template #default="scope" @click="openAddress(scope.row)">
                            <el-row style="margin-bottom: 2px">
                                <el-col>
                                    <span v-if="scope.row.utilityAddress.flatOrUnitNumber">
                                        {{ scope.row.utilityAddress.flatOrUnitType }}
                                        {{ scope.row.utilityAddress.flatOrUnitNumber }},
                                    </span>

                                    <span v-if="scope.row.utilityAddress.floorOrLevelNumber">
                                        FLOOR {{ scope.row.utilityAddress.floorOrLevelNumber }},
                                    </span>

                                    <span v-if="scope.row.utilityAddress.lotNumber">
                                        LOT {{ scope.row.utilityAddress.lotNumber  }},
                                    </span>

                                    <span v-if="scope.row.utilityAddress.houseNumberSuffix">
                                        {{ scope.row.utilityAddress.houseNumber + scope.row.utilityAddress.houseNumberSuffix }}
                                    </span>
                                    <span v-else>
                                        {{ scope.row.utilityAddress.houseNumber }}
                                    </span>
                                    
                                    
                                    {{ scope.row.utilityAddress.addressLine1 }}
                                    {{ scope.row.utilityAddress.addressLine2 }}
                                    {{ scope.row.utilityAddress.streetName }}
                                    {{ scope.row.utilityAddress.streetType }}
                                    {{ scope.row.utilityAddress.suburbOrPlaceOrLocality }},
                                    {{ scope.row.utilityAddress.stateOrTerritory }},
                                    {{ scope.row.utilityAddress.postcode }}
                                </el-col>
                            </el-row>
                        </template>
                    </el-table-column>
                </el-table>
                
                <!-- <div class="p-4 mt-4 bg-gray-0 rounded-lg border-2 border-gray-0 flex flex-row gap-4">
                    <div class="flex-none">
                        <img src="/icons/ri_information-fill.png" class="w-8" />
                    </div>
                    <div>
                        <p class="text-justify lg:text-left">
                            <b>Important:</b> By entering your supply address details you consent to us 
                            looking up this information in Australian Energy Database so we can give you 
                            products and pricing information most relevant to you and to set up or 
                            transfer your energy supply. 
                        </p>
                    </div>
                </div> -->

            </template>
        </template>
        <template v-if="showContact">
            <el-form :model="contact" label-width="120px" label-position="top">
                <el-row>
                    <el-col>
                        <el-alert
                            :title="isExpand ? cs_message_manual : cs_message_empty"
                            :description="cs_message"
                            :type="isExpand ? 'info' : 'warning'"
                            :closable="false"
                            show-icon
                        />
                    </el-col>
                </el-row>
                <el-row :gutter="4">
                    <el-col :span="8" :xs="24">
                        <el-form-item label="Name">
                        <el-input v-model="contact.name" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" :xs="24">
                        <el-form-item label="Phone Number">
                        <el-input v-model="contact.phone" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" :xs="24">
                        <el-form-item label="&nbsp;">
                        <el-button
                            style="width: 100%"
                            type="primary"
                            :icon="Message"
                            :disable="isLoading"
                            >Send</el-button
                        >
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </template>
    </div>
</template>

<style lang="scss" scoped>
.el-row {
  margin-bottom: 4px;
}

.el-form-item {
  margin-bottom: 4px;
  white-space: nowrap;
  overflow: hidden;
}

.el-form-item * {
  margin-bottom: 2px;
}

.el-table.is-scrolling-left
  .el-table-fixed-column--right.is-first-column::before {
  box-shadow: none;
}
</style>
